<template>
  <div class="cus-sidebar-item">
    <!-- 一级菜单不带子菜单 -->
    <el-menu-item
      v-if="item.single"
      :index="item.index"
      :class="activeIndex == item.index ? 'is-active-cus' : ''"
      :id="item.idText"
      @click.native="menuClick(item)"
    >
      <div :title="ISCOLLAPSE ? item.columnName : ''">
        <img
          :src="activeIndex == item.index ? item.activeIcon : item.icon"
          class="menu-icon"
        />
        <span class="menu-title">{{ item.columnName }}</span>
        <img
          v-if="item.childrenList && item.childrenList.length > 0"
          :src="activeIndex == item.index ? iconArrowActive : iconArrow"
          class="menu-icon-arrow"
        />
      </div>
    </el-menu-item>
    <el-submenu
      v-else
      v-show="item.childrenList.length > 0"
      :index="item.index"
      :id="item.idText"
      :class="{
        'is-active-cus2': activeIndex == item.index,
        'is-collapse': ISCOLLAPSE,
        'defined-menu': item.index == '5'
      }"
    >
      <template slot="title">
        <img
          :src="activeIndex == item.index ? item.activeIcon : item.icon"
          class="menu-icon"
        />
        <span class="menu-title">{{ item.title }}</span>
        <img
          :src="activeIndex == item.index ? iconArrowActive : iconArrow"
          class="menu-icon-arrow"
        />
      </template>
      <!-- 二级菜单 -->
      <div
        v-for="child in item.childrenList"
        :key="child.columnId"
        class="sub-child-menu sub-child-item"
      >
        <el-submenu
          v-if="child.childrenList && child.childrenList.length > 0"
          :index="child.index"
          :id="child.idText"
          class="defined-el-submenu"
        >
          <span
            slot="title"
            class="defined-title"
            :class="{
              'is-active-title': subActiveIndex == child.index
            }"
            @click.self="menuClick(child)"
          >
            <!-- {{ child.columnName }} -->
            <span class="column-name">{{ child.columnName }}</span>
            <span v-if="isCount" class="count-number">
              {{ child.count == 0 || child.count ? `(${child.count})` : '' }}
            </span>
          </span>
          <el-menu-item
            v-for="subChild in child.childrenList"
            :key="subChild.columnId"
            :index="subChild.index"
          >
            <span
              class="sub-defined-title"
              :class="{
                'is-active-title': subActiveIndex == subChild.index
              }"
              @click="menuClick(subChild)"
            >
              <!-- {{ subChild.columnName }} -->
              <span class="column-name">{{ subChild.columnName }}</span>
              <span v-if="isCount" class="count-number">
                {{
                  subChild.count == 0 || subChild.count
                    ? `(${subChild.count})`
                    : ''
                }}
              </span>
            </span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item
          v-else
          :class="subActiveIndex == child.index ? 'is-active-cus' : ''"
          :index="child.index"
          :id="child.idText"
          @click="menuClick(child)"
        >
          <span class="column-name">{{ child.columnName }}</span>
          <span v-if="isCount" class="count-number">
            {{ child.count == 0 || child.count ? `(${child.count})` : '' }}
          </span>
        </el-menu-item>
      </div>
    </el-submenu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    router: {
      type: Boolean,
      default: false
    },

    // eslint-disable-next-line vue/require-default-prop
    activeIndex: {
      type: String,
      dafault: '0'
    },
    // eslint-disable-next-line vue/require-default-prop
    subActiveIndex: {
      type: String,
      dafault: ''
    },

    isShowRelateReal: {
      type: Boolean,
      default: false
    },
    isCount: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('micro/sidebar', { ISCOLLAPSE: 'isCollapse' })
  },
  data() {
    return {
      activeIndexRelate: '0',
      subActiveIndexRelate: '',
      iconArrow: require('@main/src/assets/img/sidebar/icon-arrow.png'),
      iconArrowActive: require('@main/src/assets/img/sidebar/icon-arrow-down.png')
    }
  },

  methods: {
    handleActiveRelate(value) {
      this.activeIndexRelate = value.activeIndexRelate
      this.subActiveIndexRelate = value.subActiveIndexRelate
    },

    menuClick(value) {
      // 是否路由模式跳转
      if (this.router) {
        this.$emit('routerChangeUp', value)
        return
      }
      // 外链模式
      if (value.type == 'link') {
        let routeUrl = this.$router.resolve({
          path: value.path,
          query: value.query
        })
        if (value.isOpenTag) {
          window.open(routeUrl.route.fullPath, '_blank')
        } else {
          window.open(routeUrl.route.fullPath, '_self')
        }
        return
      }
      this.$emit('handleActiveIndex', {
        activeIndex: value.parentIndex,
        subActiveIndex: value.index,
        defaultActive: value.parentIndex,
        isSubMenu: value.isSubMenu
      })
      this.$emit('routerChangeUp', value)
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/css/var.scss';
.el-menu-item {
  color: #404040;
}
.cus-sidebar-item {
  margin-bottom: 5px;
  // 一级
  .el-menu-item {
    color: #404040;
    font-size: 14px;
    padding-left: 22px !important;

    img {
      width: 17px;
      height: 17px;
    }
    .sub-menu-title {
      font-weight: bold;
      padding-left: 6px;
    }
    .menu-title {
      font-size: 14px;
      padding-left: 14px;
      font-weight: bold;
    }
    &.is-active-cus {
      color: #4054af;
      background-color: #d8ddf6;
      border-right: 2px solid #4054af;
    }
  }
  // 多级
  .el-submenu {
    color: #404040;
    position: relative;
    .menu-icon {
      width: 17px;
      height: 17px;
    }
    .menu-title {
      font-size: 14px;
      padding-left: 14px;
      font-weight: bold;
    }
    .menu-icon-arrow {
      width: 12px;
      position: absolute;
      right: 22px;
      top: 13px;
      transform: rotate(-90deg);
    }

    &.is-opened {
      .menu-icon-arrow {
        transform: rotate(0deg);
      }
      .el-submenu__title {
        .menu-title {
          color: #404040;
        }
      }
    }
    &.is-active-cus2 {
      .el-submenu__title {
        .menu-title {
          color: #4054af;
        }
      }

      &.is-collapse {
        .el-submenu__title {
          background-color: #d8ddf6;
          border-right: 2px solid #4054af;
        }
      }
    }

    &.is-active {
      .el-submenu__title {
        .menu-title {
          color: #4054af;
        }
      }
    }
    &.is-active-cus {
      .el-submenu__title {
        border-right: 2px solid #4054af;
        background-color: #d8ddf6;
        .menu-title {
          color: #4054af;
        }
      }
    }

    .el-menu-item {
      min-width: 185px;
      padding-left: 50px !important;
      padding-right: 35px !important;

      .column-name {
        display: inline-block;
        vertical-align: middle;
        max-width: 107px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .count-number {
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  // 隐藏默认
  .el-submenu__icon-arrow {
    display: none;
  }

  .defined-menu {
    .sub-child-menu.sub-child-item .el-menu-item.is-active {
      border: none;
      background-color: #fff;
    }
    .sub-child-menu.sub-child-item .el-menu-item.is-active-cus {
      background-color: #d8ddf6;
      border-right: 2px solid #4054af;
    }
    .defined-el-submenu {
      font-size: 12px;
      padding: 0 !important;
    }
    .sub-child-menu.sub-child-item .el-submenu .el-submenu__title {
      padding: 0 !important;
    }

    .defined-title {
      display: inline-block;
      width: 100%;
      padding-left: 50px;
      max-width: 148px;
      font-size: 14px;
      &.is-active-title {
        background-color: #d8ddf6;
        border-right: 2px solid #4054af;
      }
    }
    .sub-child-menu.sub-child-item .el-submenu .el-menu-item {
      padding: 0 !important;

      &.is-active {
        .sub-defined-title {
          background-color: #d8ddf6;
          border-right: 2px solid #4054af;
        }
      }

      .sub-defined-title {
        display: inline-block;
        width: 100%;
        padding-left: 60px !important;
        max-width: 138px;
        &.is-active-title {
          background-color: #d8ddf6;
          border-right: 2px solid #4054af;
        }
      }
    }
  }
}

.is-collapse {
  .el-submenu {
    .menu-title,
    .menu-icon-arrow {
      display: none;
    }
  }
  .menu-title {
    display: none;
  }
}
.sub-child-menu.sub-child-item {
  .tree-menu {
    .el-submenu__title {
      font-size: 14px;
      color: #404040;
      padding: 0 !important;
      .menu-icon-arrow {
        transform: rotate(0deg);
      }

      .child-menu-title {
        padding: 0px 0 0 50px;
        width: 73%;
        display: inline-block;
        &.is-active-cus {
          color: #4054af;
          background-color: #d8ddf6;
          border-right: 2px solid #4054af;
        }
      }
    }
  }

  .el-menu-item {
    &.is-active {
      color: #4054af;
      background-color: #d8ddf6;
      border-right: 2px solid #4054af;
    }
  }

  .el-submenu {
    .el-submenu__title {
      color: #404040;
      // padding-left: 50px !important;
    }
    .el-menu-item {
      padding-left: 60px !important;
      &.is-active {
        background-color: #d8ddf6;
        border-right: 2px solid #4054af;
      }
    }

    &.is-opened {
      .menu-icon-arrow {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
