// 权限管理
export default {
  name: 'authorityManagement',
  // 设置站点附件类型接口
  setSiteExtInfo: {
    url: '/rc-pub/api/site/setAttachType'
  },
  // 根据站点id获取角色权限列表
  getRoleList: {
    url: '/rc-pub/api/role/findBySiteId'
  },
  // 设置角色下载权限
  setAttachType: {
    url: '/rc-pub/api/role/setAttachType'
  }
}
