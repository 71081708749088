export default {
  name: 'groupInfo',
  getGroupInfo: {
    url: '/magazine/orginfo/get.do'
  },
  postGroupInfo: {
    url: '/magazine/orginfo/save.do'
  },
  uploadImage: {
    url: '/magazine/uploadImage.do'
  },
  download: {
    url: '/common/magazineDownload.do'
  }
}
