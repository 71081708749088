//刊群列表
export default {
  name: 'periodicalGroup',
  getPeriodicalGroup: {
    url: '/rc-pub/api/rc-publication/detail/search'
  },
  // 编辑
  periodicalGroupEdit(id) {
    return `/rc-pub/api/rc-publication/update/${id}`
  },
  // 编辑-新
  periodicalGroupEditNew: {
    url: '/rc-pub/api/rc-publication/update'
  },
  // 获取刊物详情
  periodicalDetail(id) {
    return `/rc-pub/api/rc-publication/detail/${id} `
  },
  // 获取刊物详情-新
  periodicalDetailNew(id) {
    return `/rc-pub/api/rc-publication/${id} `
  },
  signPeriodical: {
    url: '/rc-pub/api/rc-publication/batchUpdateOa'
  },
  // 删除
  getNewsDelete: {
    url: (id) => {
      return `/rc-pub/api/rc-news/delete/${id}`
    }
  },
  // 刊群管理，查询刊物来源
  getShowSource: {
    url: '/rc-pub/api/rc-publication/showSource'
  }
}
