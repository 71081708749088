export default {
  name: 'topicLib',
  // 子库列表查询
  getTopicLibList: {
    url: '/rc-pub/api/rc-subPool/page'
  },
  // 添加子库
  postTopicLib: {
    url: '/rc-pub/api/rc-subPool/save'
  },
  // 添加子库-按自定义学科分类导航
  postTopicLibForCustomize: {
    url: '/rc-pub/api/rc-subPool/saveCustomSubject'
  },
  // 删除子库
  deleteTopicLib: {
    url: '/rc-pub/api/rc-subPool/delete'
  },
  // 获取子库详情
  getTopicLibInfo(id) {
    return `/rc-pub/api/rc-subPool/get/${id}`
  },
  // 上架子库
  putShelvesOfTopicLib: {
    url: '/rc-pub/api/rc-subPool/putShelves'
  },
  // 给子库添加资源
  postResourceToTopicLib: {
    url: '/rc-pub/api/rc-subPoolRes/add'
  },
  // 删除子库下的资源
  deleteTopicLibResource: {
    url: '/rc-pub/api/rc-subPoolRes/deleteRes'
  },
  // 子库添加弹框里-资源列表查询
  getTopicLibResourceList: {
    url: '/rc-pub/api/rc-subPoolRes/searchRes'
  },
  // 子库-详情页-左侧树表-
  getTopicLibTreeData: {
    url: '/rc-pub/front/search/clusters'
  },
  // 子库-详情页-资源列表页
  getResourceList: {
    url: '/rc-pub/front/search'
  },
  // 子库-详情页-获取搜索内容的分词
  getAnalyzeWords: {
    url: '/rc-pub/front/search/analyze'
  },
  // 子库-详情页-左侧树表--针对自定义学科
  getTopicLibTreeDataOfCustomize: {
    url: '/rc-pub/api/rc-subPool/getCluster'
  },
  // 子库-详情页-添加资源--针对自定义学科
  postResourceToTopicLibOfCustomize: {
    url: '/rc-pub/api/rc-subPool/addArticleCategory'
  },
  // 子库-详情页-移除资源--针对自定义学科
  deleteTopicLibResourceOfCustomize: {
    url: '/rc-pub/api/rc-subPool/removeArticleCategory'
  },
  // 子库添加一级分类学科
  addFirstLevelDiscipline: {
    url: '/rc-pub/api/rc-subPool/addFirstLevelDiscipline'
  }
}
