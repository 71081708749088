export default {
  // 传播效果统计
  name: 'communicationEffectStatistics',
  // 获取期刊整期和专题类型
  GetType: {
    url: '/das-api/vas/article/statistics/getType'
  },
  // 获取期刊年份
  GetYear: {
    url: '/das-api/vas/article/statistics/getYear'
  },
  GetTaskList: {
    url: '/das-api/vas/networkjournal/journalPage'
  },
  // 获取期数或专题
  GetIssue: {
    url: '/das-api/vas/article/statistics/getIssue'
  },
  // 推送前后官网访问量
  GetSumVisitCount: {
    url: '/das-api/vas/article/statistics/getSumVisitCount'
  },
  // 推送前后单篇文章阅读情况
  GetVisitCount: {
    url: '/das-api/vas/article/statistics/getVisitCount'
  },
  // 推送前后单篇文章阅读情况table表格
  ArticleClickRankList: {
    url: '/das-api/vas/article/statistics/articleClickRankList'
  },
  // 获取论文和论文相关作者详情
  GetArticleAndAuthorInfo: {
    url: '/das-api/vas/article/getArticleAndAuthorInfo'
  },
  // 阅读文章用户机构分布
  GetArticleAuthorOrg: {
    url: '/das-api/vas/push/log/getArticleAuthorOrg'
  },
  // 阅读文章用户期刊分布
  GetArticleAuthorMag: {
    url: '/das-api/vas/push/log/getArticleAuthorMag'
  },
  // 阅读文章学者相关文章
  GetReadRelatedArticleList: {
    url: '/das-api/vas/push/log/getReadRelatedArticleList'
  },
  // 分析报告总数统计
  GetPushTaskReportInfo: {
    url: '/das-api/vas/push/task/pushTaskReport/info'
  },
  // 邮件传播、微信传播
  GetPushTaskReportSpread: {
    url: '/das-api/vas/push/task/pushTaskReport/spread'
  },
  // 近五期传播前后官网访问量
  GetWebsiteVisit: {
    url: '/das-api/vas/push/task/pushTaskReport/websiteVisit'
  },
  // 本年度文献关键词分布
  GetKeywords: {
    url: '/das-api/vas/push/task/pushTaskReport/yearKeywordsStatistic'
  },
  // 引证追踪
  GetLatestCitationCount: {
    url: '/das-api/vas/push/task/pushTaskReport/latestCitationCount'
  },
  // 传播效果趋势分析
  GetEmailOnlyCount: {
    url: '/das-api/vas/push/task/pushTaskReport/emailOnlyCount'
  },
  // 传播路径分析-每期
  GetDiffusePath: {
    url: '/das-api/vas/push/task/diffusePath'
  },
  // 传播路径分析-年度
  GetYearDiffusePath: {
    url: '/das-api/vas/push/task/annualReport/terrain/analysis'
  },
  // 年度报告
  // 基本信息和引流量
  GetAnnualReportInfo: {
    url: '/das-api/vas/push/task/annualReport/info'
  },
  // 本刊学者排行
  GetOfficialAuthorRead: {
    url: '/das-api/vas/push/task/annualReport/officialAuthorRead'
  },
  // 白名单排行
  GetWhiteAuthorRead: {
    url: '/das-api/vas/push/task/annualReport/whiteAuthorRead'
  },
  // 机构来源分布及排行（人数Top15）
  GetOpenEmailOrg: {
    url: '/das-api/vas/push/task/annualReport/openEmailOrg'
  },
  // 引用刊作者来源分布及排行（人数Top10）
  GetReferenceMag: {
    url: '/das-api/vas/statistics/referenceMag'
  },
  // 引证刊作者来源分布及排行（人数Top10）
  GetCitationMag: {
    url: '/das-api/vas/statistics/citationMag'
  },
  // 年度被引
  GetSumIssueArticleCitation: {
    url: '/das-api/vas/article/sumIssueArticleCitation'
  },
  //本年度文献被引排行Top10
  GetCountRootArticleCitations: {
    url: '/das-api/vas/article/countRootArticleCitations'
  },
  // 本年度发文机构被引统计Top10
  GetCountRootMagOrg: {
    url: '/das-api/vas/article/countRootMagOrg'
  },
  // 本年度引证期刊引证统计Top10
  GetCountMagCitations: {
    url: '/das-api/vas/article/countMagCitations'
  },
  // 年度关键词
  GetYearKeywordsStatistic: {
    url: '/das-api/vas/push/task/pushTaskReport/yearKeywordsStatistic'
  },
  // 年度阅读量排行榜(引流)
  GetArticleClickRankListYL: {
    url: '/das-api/vas/article/statistics/articleLogClickRankList'
  },
  // 本年度引证期刊引证统计Top10
  GetArticleClickRankList: {
    url: '/das-api/vas/article/statistics/articleClickRankList'
  }
}
