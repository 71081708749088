export default {
  name: 'internetMagazine',
  bannerUpload: {
    url: '/magazine/bannerUpload.do'
  },
  bannerStateChange: {
    url: '/magazine/bannerStateChange.do'
  },
  htmlPreview: {
    url: '/magazine/htmlPreview.do'
  },
  htmlBannerGetState: {
    url: '/magazine/htmlBannerGetState.do'
  }
}
