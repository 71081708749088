// 限制同时请求的数量为一次
export default class FetchLimit {
  constructor() {
    this.fetching = false
    this.queue = []
  }
  async fetch(fn /*需要返回promise*/) {
    if (this.fetching) {
      const promise = new Promise((resolve) => {
        this.queue.push(resolve)
      })
      return promise
    } else {
      this.fetching = true
      const data = await fn()
      this.fetching = false

      this.queue.forEach((item) => item(data))
      return data
    }
  }
}
