const store = {
  namespaced: true,
  state: {
    magId: ''
  },
  getters: {},
  mutations: {
    setMagId: (state, value) => {
      state.magId = value
    }
  },
  actions: {
    magIdFun({ commit }, value) {
      commit('setMagId', value)
    }
  }
}

export default store
