import APIS from '../../apis'
import { decode } from 'kjqk-commons/src/common/index.js'

export default {
  namespaced: true,
  state: {
    userInfo: {},
    oldUserInfo: {}
  },
  getters: {
    USER_NAME: (state) => state.userInfo && state.userInfo.name
  },
  mutations: {
    INIT_STATE(state) {
      Object.keys(state).forEach((key) => {
        state[key] = null
      })
    },
    UPDATE_STATE: (state, payload) => (state[payload.name] = payload.data)
  },
  actions: {
    async GET_USER_INFO({ commit }, magazineId) {
      magazineId = magazineId || this.getters['micro/magazine/MAGAZINE_ID']
      if (!magazineId || magazineId === '') {
        return false
      }
      const { data } = await APIS.portal.getUserInfo({
        params: {
          magazineid: magazineId
        }
      })
      const decodeData = data && JSON.parse(decodeURIComponent(decode(data)))
      commit('UPDATE_STATE', {
        name: 'userInfo',
        data: decodeData && decodeData.data
      })
      return decodeData
    },
    async GET_USER_INFO_WATCH({ state }) {
      let info = state['userInfo']
      if (!info?.id) {
        await new Promise((resolve) => {
          const unWatch = this.watch(
            function (state) {
              return state.micro.user.userInfo
            },
            (val) => {
              if (val) {
                info = val
                unWatch()
                resolve()
              }
            }
          )
        })
      }
      return info
    },
    async GET_OLD_USER_INFO({ commit }, params) {
      const {
        data: { data: advObj }
      } = await APIS.portal.getAdvid({
        params: {
          mobile: params.mobile
        },
        headers: {
          orgCode: params.orgCode
        }
      })
      if (advObj && advObj !== null) {
        commit('UPDATE_STATE', {
          name: 'oldUserInfo',
          data: JSON.parse(decodeURIComponent(decode(advObj)))
        })
        return JSON.parse(decodeURIComponent(decode(advObj)))
      } else {
        return {}
      }
    },
    // 仅作为判断账号禁用情况使用
    async GET_USER_PERMISSION() {
      const {
        data: { data }
      } = await APIS.portal.getUserMagazine({
        domain: window.location.host
      })
      const magazineId = data?.[0]?.magazineId || ''
      if (!magazineId || magazineId === '') {
        return false
      }
      const res = await APIS.portal.getUserInfo({
        params: {
          magazineid: magazineId
        }
      })
      const decodeData =
        res?.data && JSON.parse(decodeURIComponent(decode(res.data)))
      return decodeData.data
    }
  }
}
