import createAPI from '../middleware/createAPI'

export default {
  // 获得消息列表（出版中心）
  getList: (config) =>
    createAPI('/magazine/userCenter/systemMessage/list.do', 'get', config),
  // 设置消息为已读
  setMessageRead: (config) =>
    createAPI(
      '/magazine/userCenter/systemMessage/setMessageRead.do',
      'get',
      config
    ),
  // 获得未读消息列表
  unReadMessage: (config) =>
    createAPI(
      '/magazine/userCenter/systemMessage/unReadMessage.do',
      'get',
      config
    ),
  // 设置消息类型
  setMessageType: (config) =>
    createAPI(
      '/magazine/userCenter/systemMessage/setMessageType.do',
      'get',
      config
    ),
  // 获取消息文件
  getMessageFile: (config) =>
    createAPI(
      '/magazine/userCenter/systemMessage/getMessageFile.do',
      'get',
      config
    ),
  // 删除消息
  delete: (config) =>
    createAPI('/magazine/userCenter/systemMessage/delete.do', 'post', config),
  // 获取系统消息（运营平台）
  getSysMessage: (config) =>
    createAPI('/portal/operate/notice/systemMessage', 'get', config)
}
