<template>
  <div class="increment-service production-platform" v-show="showSideBar">
    <Sidebar
      :default-active="defaultActive"
      :menu-data="menuData"
      :router="false"
      @routerChange="routerChange"
    />
  </div>
</template>
<script>
import { getItem, matchRouteObj, Get } from '@kc/common/index.js'
import { mapState, mapGetters } from 'vuex'
import Sidebar from '@main/src/components/Sidebar'
export default {
  name: 'IncrementService',
  components: {
    Sidebar
  },
  data() {
    return {
      defaultActive: '2-2',
      isChange: true,
      menuData: [],
      defaultMenuData: [
        {
          columnName: '发文分析',
          index: '1',
          single: true,
          path: '/postAnalysis',
          icon: require('@assets/img/postAnalysis.png'),
          activeIcon: require('@assets/img/postAnalysis-active.png'),
          code: '_fwfx'
        },
        {
          title: '邮件传播',
          index: '2',
          icon: require('@assets/img/activeCommunication.png'),
          activeIcon: require('@assets/img/activeCommunication-active.png'),
          code: '_zdcb',
          childrenList: [
            {
              columnName: '推送列表',
              parentIndex: '2',
              id: '1',
              index: '2-1',
              path: '/emailPushList',
              code: '_dpts'
            },
            {
              columnName: '阅读统计',
              parentIndex: '2',
              id: '2',
              index: '2-2',
              path: '/emailPush',
              code: '_zdcb'
            }
          ]
        },
        {
          title: '微信传播',
          index: '3',
          icon: require('@assets/img/activeCommunication.png'),
          activeIcon: require('@assets/img/activeCommunication-active.png'),
          code: '_zdcb',
          childrenList: [
            {
              columnName: '图文消息',
              parentIndex: '3',
              id: '1',
              index: '3-1',
              path: '/pictrueTextMsg',
              code: '_zdcb'
            },
            {
              columnName: '阅读统计',
              parentIndex: '3',
              id: '2',
              index: '3-2',
              path: '/weChatPush',
              code: '_zdcb'
            }
          ]
        },
        {
          title: '传播效果',
          index: '4',
          icon: require('@assets/img/citationTracking.png'),
          activeIcon: require('@assets/img/citationTracking-active.png'),
          code: '_zdcb',
          childrenList: [
            {
              columnName: '单期效果',
              parentIndex: '4',
              id: '1',
              index: '4-1',
              path: '/singleEffect',
              code: '_zdcb'
            },
            {
              columnName: '传播表现',
              parentIndex: '4',
              id: '2',
              index: '4-2',
              path: '/communicationEffect',
              code: '_zdcb'
            },
            {
              columnName: '引证追踪',
              parentIndex: '4',
              id: '3',
              index: '4-3',
              path: '/citationTrackingPage',
              code: '_yztj'
            }
          ]
        },
        {
          title: '报告下载',
          index: '5',
          icon: require('@assets/img/download.png'),
          activeIcon: require('@assets/img/download-active.png'),
          code: '_bgxz',
          childrenList: [
            {
              columnName: '单期报告',
              parentIndex: '5',
              id: '1',
              index: '5-1',
              path: '/downloadIssueReport',
              code: '_bgxz'
            },
            {
              columnName: '年度报告',
              parentIndex: '5',
              id: '2',
              index: '5-2',
              path: '/downloadYearReport',
              code: '_bgxz'
            }
          ]
        },
        {
          columnName: '引证统计',
          index: '6',
          single: true,
          path: '/citationTracking',
          icon: require('@assets/img/citationTracking.png'),
          activeIcon: require('@assets/img/citationTracking-active.png'),
          code: '_yzzz'
        },
        {
          title: '文献聚合',
          index: '7',
          icon: require('@assets/img/contentAggregation.png'),
          activeIcon: require('@assets/img/contentAggregation-active.png'),
          code: '_nrjh',
          childrenList: [
            {
              columnName: '优先出版文章',
              parentIndex: '7',
              id: '1',
              index: '7-1',
              path: '/priorityPublication',
              code: '_nrjh'
            },
            {
              columnName: '整期发表期刊',
              parentIndex: '7',
              id: '2',
              index: '7-2',
              path: '/publishedJournals',
              code: '_nrjh'
            },
            {
              columnName: '高被引文章',
              parentIndex: '7',
              id: '3',
              index: '7-3',
              path: '/highlyCitedArticles',
              code: '_nrjh'
            }
          ]
        },
        {
          title: '资讯聚合',
          index: '8',
          icon: require('@assets/img/informationAggregation.png'),
          activeIcon: require('@assets/img/informationAggregation-active.png'),
          code: '_zyjh',
          childrenList: [
            {
              columnName: '学术资讯',
              parentIndex: '8',
              id: '1',
              index: '8-1',
              path: '/academicInformation',
              code: '_zyjh'
            },
            {
              columnName: '会议资讯',
              parentIndex: '8',
              id: '2',
              index: '8-2',
              path: '/conferenceInformation',
              code: '_zyjh'
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters('micro/magazine', ['MAGAZINE_ID', 'centers']),
    ...mapState('producePlantForm', ['userInfo', 'isSaveSessionStorage']),
    ...mapState('micro/user', { USERINFO: 'userInfo' }),
    showSideBar() {
      let filterRouter = ['/emailPreviewHtml']
      return !filterRouter.includes(this.$route.path)
    }
  },
  watch: {
    isSaveSessionStorage(newVal) {
      if (newVal) {
        this.checkHasAuthority()
      }
      this.$nextTick(() => {
        this.isChange = true
      })
    },
    MAGAZINE_ID() {
      this.getMagByPlatId()
    }
  },
  created() {
    this.defaultActive = this.$route.query.columnId
      ? this.$route.query.columnId
      : '1'
    console.log(this.defaultActive, 'this.defaultActive')
  },
  mounted() {
    this.getMagByPlatId()
    this.getMenuData()
  },
  methods: {
    // 获取magId
    getMagByPlatId() {
      const params = {
        platId: this.MAGAZINE_ID
      }
      /* this.menuData = this.defaultMenuData
      const params = {
        platId: '63a4a5fa54ed43669fe43ecea956bdde'
      } */
      let url = '/das-api/vas/mag/getMagByPlatId'
      let _this = this
      Get(url, { params }).then((res) => {
        let { data } = res.data
        if (data) {
          sessionStorage.setItem('magId', data.id)
          _this.$store.dispatch('incrementService/magIdFun', data.id)
        } else {
          sessionStorage.setItem('magId', null)
          _this.$store.dispatch('incrementService/magIdFun', null)
        }
      })
    },
    // 获取权限列表
    getMenuData() {
      const params = {
        magazineid: this.MAGAZINE_ID,
        /* magazineid: '63a4a5fa54ed43669fe43ecea956bdde', */
        pid: 'precisionPush'
      }
      let url = '/portal/usermagazinecentermenu'
      Get(url, { params }).then((res) => {
        let { data } = res.data
        /* console.log(res, 'res')
        let data = [
          {
            id: '_fwfx',
            text: '发文分析',
            code: '_fwfx',
            src: '_fwfx',
            seq: 1,
            pid: 'precisionPush',
            type: 0
          },
          {
            id: '_zdcb',
            text: '整期推送',
            code: '_zdcb',
            src: '_zdcb',
            seq: 2,
            pid: 'precisionPush',
            type: 0
          },
          {
            id: '_bgxz',
            text: '报告下载',
            code: '_bgxz',
            src: '_bgxz',
            seq: 3,
            pid: 'precisionPush',
            type: 0
          },
          {
            id: '_yzzz',
            text: '引证统计',
            code: '_yzzz',
            src: '_yzzz',
            seq: 4,
            pid: 'precisionPush',
            type: 0
          },
          {
            id: '_nrjh',
            text: '文献聚合',
            code: '_nrjh',
            src: '_nrjh',
            seq: 5,
            pid: 'precisionPush',
            type: 0
          },
          {
            id: '_zyjh',
            text: '资讯聚合',
            code: '_zyjh',
            src: '_zyjh',
            seq: 6,
            pid: 'precisionPush',
            type: 0
          },
          {
            id: '_dpts',
            text: '单篇推送',
            code: '_dpts',
            src: '_dpts',
            seq: 7,
            pid: 'precisionPush',
            type: 0
          },
          {
            id: '_yztj',
            text: '引证追踪',
            code: '_yztj',
            src: '_yztj',
            seq: 8,
            pid: 'precisionPush',
            type: 0
          }
        ] */
        if (data && data.length > 0) {
          this.menuData = this.handleRecursionTree(this.defaultMenuData, data)
          let filterRouter = [
            '/emailPreviewHtml',
            '/details',
            '/report',
            '/analysisReport',
            '/analysisYearReport',
            '/publishedJournalsDetails',
            '/citationTrackingDetails'
          ]
          if (filterRouter.includes(this.$route.path)) return
          let haveZDCB = false
          data.map((item) => {
            if (item.code === '_zdcb') {
              haveZDCB = true
            }
          })
          if (haveZDCB) {
            let routerObj = {
              path: '/emailPush',
              index: '2-2'
            }
            this.routerChange(routerObj)
          } else {
            let obj = {}
            this.defaultMenuData.map((item) => {
              if (item.childrenList && item.childrenList.length > 0) {
                item.childrenList.map((it) => {
                  if (it.code === data[0].code) {
                    obj = it
                  }
                })
              } else {
                if (item.code === data[0].code) {
                  obj = item
                }
              }
            })
            this.routerChange(obj)
          }
        } else {
          this.$router.push({
            path: '/noData'
          })
        }
      })
    },
    handleRecursionTree(originData, data) {
      let menuData = originData
      for (let i = 0; i < menuData.length; i++) {
        // 1、遍历
        if (menuData[i].childrenList && menuData[i].childrenList.length > 0) {
          for (let j = 0; j < menuData[i].childrenList.length; j++) {
            if (
              data.findIndex(
                (it) => it.code === menuData[i].childrenList[j].code
              ) == -1
            ) {
              menuData[i].childrenList.splice(j, 1)
              j--
            }
          }
        } else {
          if (data.findIndex((item) => item.code === menuData[i].code) === -1) {
            menuData.splice(i, 1)
            i--
          }
        }
      }
      return menuData
    },
    saveActiveTab() {
      let name = this.$route.name
      this.defaultActive = name
    },
    // 判断修改后的刊物是否有资源中心权限
    checkHasAuthority() {
      let [menuArray, flag] = [JSON.parse(getItem('mp')), false]
      menuArray.map((item, index) => {
        if (matchRouteObj[item.name] === 'incrementService') {
          flag = true
        }
        if (index === menuArray.length - 1 && !flag) {
          // 变化后的刊物没有资源中心权限 需处理
          this.$router.push({ name: matchRouteObj[menuArray[0].name] })
        }
      })
    },
    // 点击一级菜单
    routerChange(value) {
      this.defaultActive = value.index
      this.$router.push({
        path: value.path,
        query: { columnId: value.index }
      })
    }
  }
}
</script>
<style lang="scss">
.increment-service {
  .cus-sidebar-item {
    &:nth-child(1) {
      .menu-icon {
        width: 18.71px !important;
        height: 16.39px !important;
      }
    }
    &:nth-child(2) {
      .menu-icon {
        width: 19.13px !important;
        height: 18.04px !important;
      }
    }
    &:nth-child(4) {
      .menu-icon {
        width: 17.18px !important;
        height: 17.17px !important;
      }
    }
    &:nth-child(5) {
      .menu-icon {
        width: 20px !important;
        height: 20px !important;
      }
    }
    &:nth-child(6) {
      .menu-icon {
        width: 23px !important;
        height: 23px !important;
        margin-left: -4px;
      }
    }
  }
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
.side-list {
  line-height: inherit !important;
}

.increment-service {
  height: 100%;
  .select-option {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tab-ul {
    padding: 0 !important;
    margin: 0 !important;
    background: #e4e4e4;

    li {
      width: 100%;
      height: 50px;
      line-height: 50px;
      color: #000;
      list-style: none;
      border-bottom: solid 1px #f2f2f2;

      a {
        text-decoration: none;
      }
    }
  }
  .el-menu {
    li {
      padding-left: 70px !important;

      a {
        text-align: left !important;
      }
    }
    .total-title {
      width: 100%;
      padding-left: 60px !important;
      color: #333;
      font-size: 12px;
      border: none;
    }
  }
}
</style>
