export default {
  name: 'assembly',
  huiBiaoList: {
    url: '/huibian/huiBiaoList.do'
  },
  huiBiaoEdit: {
    url: '/huibian/huiBiaoEdit.do'
  },
  huiBiaoEditSubmit: {
    url: '/huibian/huiBiaoEditSubmit.do'
  },
  huiBiaoDelete: {
    url: '/huibian/huiBiaoDelete.do'
  },
  huiBiaoGroupEditSubmit: {
    url: '/huibian/huiBiaoGroupEditSubmit.do'
  }
}
