// 资讯管理
export default {
  name: 'informationManage',
  getpageBySiteIdAndTitle: {
    url: '/rc-pub/api/rc-news/pageBySiteIdAndTitle'
  },
  fileUpload: {
    url: '/rc-pub/api/files/fileUpload'
  },
  getDetailUrl: {
    url: '/rc-pub/api/rc-news/getJumpToDetailUrl'
  },
  // 新建和编辑
  postNewsSaveAndEdit: {
    url: '/rc-pub/api/rc-news/save'
  },
  // 获取资讯信息接口
  getRes(id) {
    return `/rc-pub/api/rc-news/${id}`
  },
  // 删除
  getNewsDelete: {
    url: '/rc-pub/api/rc-news/delete'
  },
  // 发布
  getPutOnShelves: {
    url: '/rc-pub/api/rc-news/putOnShelves'
  },
  // 下架
  getPullOffShelves: {
    url: '/rc-pub/api/rc-news/pullOffShelves'
  },
  getPullOffMessage: {
    url: '/rc-pub/api/rc-news/pullOffShelvesMessage'
  },
  top: {
    url: '/rc-pub/api/rc-news/top'
  },
  untop: {
    url: '/rc-pub/api/rc-news/untop'
  },
  // 获取自动更新的资讯列表
  getAutoUpdateNews: {
    url: '/rc-pub/api/rc-news/getAutoUpdateNews'
  },
  // 获取是否配置采集频道的状态
  getVasNewsStatus: {
    url: '/rc-pub/api/rc-news/getVasNewsStatus'
  },
  // 获取增值服务资讯列表
  getVasNewsList: {
    url: '/rc-pub/api/rc-news/getVasNewsList'
  },
  // 转载增值服务资讯数据到发布平台
  reprintVasNews: {
    url: '/rc-pub/api/rc-news/reprintVasNews'
  },
  // 获取资讯分类列表
  getNewsTypeList: {
    url: '/rc-pub/api/rc-news/getNewsTypeList'
  },
  // 资讯分类新增、编辑、删除
  operateNewsType: {
    url: '/rc-pub/api/rc-news/operateNewsType?newsDataType=news'
  },
  // 自动更新置顶
  topAutoUpdateNews: {
    url: '/rc-pub/api/rc-news/topAutoUpdateNews'
  },
  // 获取资讯来源
  getNewsSource: {
    url: '/rc-pub/api/rc-news/getNewsSource'
  }
}
