/**
 * Created by ztt on 2018/7/11.
 */
const Base64 = require('js-base64').Base64

export function decode(key) {
  return Base64.decode(key)
}

export function encode(key) {
  return Base64.encode(key)
}
