export default {
  name: 'userInfo',
  getInfo: {
    url: '/portal/userinfo'
  },
  modifyPsd: {
    url: '/portal/updatePassword'
  },
  upload: {
    url: '/userCenter/user/upload.do'
  },
  getmessagecode: {
    url: '/portal/sendCode'
  },
  saveInfo: {
    url: '/org/portalUser/update'
  },
  updatePhone: {
    url: '/portal/modifyPhone'
  }
}
