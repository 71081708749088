import APIS from '../../apis'
import qs from 'qs'

const store = {
  namespaced: true,
  state: {
    unReadMessages: [], // 未读系统消息
    unReadArticleMessageNum: 0, // 未读稿件留言数
    unreadInsideMessageNum: 0 // 未读稿件站内信数
  },
  getters: {
    unReadMessageNum: (state) => state.unReadMessages?.length || 0
  },
  mutations: {
    // 初始化STATE，数据全部清空
    INIT_STATE(state) {
      state.unReadMessages = []
    },
    UPDATE_STATE: (state, payload) => (state[payload.name] = payload.data)
  },
  actions: {
    // 获取消息列表 出版中心
    async GET_MESSAGE_LIST(context, params) {
      const { data } = await APIS.message.getList({
        params: params
      })
      return data
    },
    // 获取消息列表 运营平台
    async GET_SYS_MESSAGE_LIST_YYPT(context, params) {
      const { data } = await APIS.message.getSysMessage({
        params: params
      })
      return data
    },
    // 获取未读消息列表
    async GET_UNREAD_MESSAGE({ dispatch }, params) {
      const { data } = await APIS.message.unReadMessage({
        params: params
      })
      if (data.status === 0) {
        dispatch('SET_UNREAD_MESSAGE', data.data || [])
      }
      return data
    },
    // 获取稿件留言未读消息数
    async GET_ARTICLE_UNREAD_MESSAGE({ dispatch }, params) {
      const { data } = await APIS.article.getArticleMessageUnreadCount({
        params: params
      })
      if (data.status === 0) {
        dispatch('SET_UNREAD_ARTICLE_MESSAGE_COUNT', data.data || 0)
      }
      return data
    },
    // 获取稿件留言列表
    async GET_ARTICLE_MESSAGES(context, params) {
      const { data } = await APIS.article.postArticleMessageRecords({
        data: params
      })
      return data
    },
    // 设置消息为已读
    async SET_READ_MESSAGE(context, params) {
      const { data } = await APIS.message.setMessageRead({
        params: params
      })
      return data
    },
    // 设置未读系统消息
    SET_UNREAD_MESSAGE({ state }, messages) {
      state.unReadMessages = messages
    },
    // 设置稿件消息全部已读
    async SET_READ_ARTICLE_MESSAGE_ALL() {
      const { data } = await APIS.article.postArticleMessageRecordsRead()
      return data
    },
    // 设置稿件消息未读数
    SET_UNREAD_ARTICLE_MESSAGE_COUNT({ state }, num) {
      state.unReadArticleMessageNum = num
    },

    /*站内信*/
    // 获取站内信未读消息数
    async GET_INSIDE_UNREAD_MESSAGE({ dispatch }) {
      const { data } = await APIS.review.getMessageInsideUnreadCount()
      if (data.status === 0) {
        dispatch('SET_UNREAD_INSIDE_MESSAGE_COUNT', data.data || 0)
      }
      return data
    },
    // 获取稿件站内信列表
    async GET_ARTICLE_INSIDE_MESSAGES(context, params) {
      const { data } = await APIS.review.getMessageInsideList({
        params
      })
      return data
    },
    // 设置站内信为已读
    async SET_READ_INSIDE_MESSAGE(context, params) {
      const { data } = await APIS.review.postMessageInsideRead({
        params: params
      })
      return data
    },
    // 设置站内信全部已读
    async SET_READ_INSIDE_MESSAGE_ALL() {
      const { data } = await APIS.review.postMessageInsideUnreadClear()
      return data
    },
    // 删除站内信
    async DEL_INSIDE_MESSAGE(context, ids) {
      const { data } = await APIS.review.deleteMessageInside(ids)
      return data
    },
    // 设置站内信未读数
    SET_UNREAD_INSIDE_MESSAGE_COUNT({ state }, num) {
      state.unreadInsideMessageNum = num
    },
    /*End 站内信*/

    // 设置消息类型
    async SET_MESSAGE_TYPE(context, params) {
      const {
        data: { data }
      } = await APIS.message.setMessageType({
        params: params
      })
      return data
    },
    // 删除角色
    async DELETE_MESSAGE(context, params) {
      const { data } = await APIS.message.delete({
        data: qs.stringify(params)
      })
      return data
    },
    // 获取消息文件
    async GET_MESSAGE_FILE(context, params) {
      const {
        data: { data }
      } = await APIS.message.getMessageFile({
        params: params
      })
      return data
    }
  }
}

export default store
