export default {
  name: 'manuscriptManage',
  getUserList: {
    url: '/magazine/magazineInfo/getFormData.do'
  },
  getJournalList: {
    url: '/magazine/magazineList.do'
  },
  uploadImg: {
    url: '/magazine/uploadImage.do'
  },
  new: {
    url: '/magazine/magazineNew.do'
  },
  titleCheck: {
    url: '/magazine/titleCheck.do'
  },
  numberCheck: {
    url: '/magazine/numberCheck.do' //刊物 CN号重复校验（武岳明）
  }
}
