import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    // redirect: '/emailPush?columnId=2-2',
    children: [
      {
        path: '/postAnalysis',
        name: 'postAnalysis',
        component: () => import('../views/postAnalysis/index.vue')
      },
      {
        path: '/emailPush',
        name: 'emailPush',
        component: () =>
          import('../views/activeCommunication/emailPush/index.vue')
      },
      {
        path: '/emailPushList',
        name: 'emailPushList',
        component: () =>
          import('../views/activeCommunication/emailPush/emailPushList.vue')
      },
      {
        path: '/emailPreviewHtml',
        name: 'emailPreviewHtml',
        component: () =>
          import('../views/activeCommunication/emailPush/emailPreviewHtml.vue')
      },
      {
        path: '/weChatPush',
        name: 'weChatPush',
        component: () =>
          import('../views/activeCommunication/weChatPush/index.vue')
      },
      {
        path: '/pictrueTextMsg',
        name: 'pictrueTextMsg',
        component: () =>
          import('../views/activeCommunication/weChatPush/pictrueTextMsg.vue')
      },
      {
        path: '/singleEffect',
        name: 'singleEffect',
        component: () =>
          import(
            '../views/activeCommunication/communicationEffect/singleEffect.vue'
          )
      },
      {
        path: '/communicationEffect',
        name: 'communicationEffect',
        component: () =>
          import('../views/activeCommunication/communicationEffect/index.vue')
      },
      {
        path: '/citationTrackingPage',
        name: 'citationTrackingPage',
        component: () =>
          import('../views/activeCommunication/citationTracking/index.vue')
      },
      {
        path: '/citationTrackingDetails',
        name: 'citationTrackingDetails',
        component: () =>
          import(
            '../views/activeCommunication/citationTracking/citationTrackingDetails.vue'
          )
      },
      {
        path: '/details',
        name: 'details',
        component: () =>
          import(
            '../views/activeCommunication/communicationEffect/articleDetails.vue'
          )
      },
      {
        path: '/report',
        name: 'report',
        component: () =>
          import('../views/activeCommunication/communicationEffect/report.vue')
      },
      {
        path: '/analysisReport',
        name: 'analysisReport',
        component: () =>
          import(
            '../views/activeCommunication/communicationEffect/analysisReport.vue'
          )
      },
      {
        path: '/analysisYearReport',
        name: 'analysisYearReport',
        component: () =>
          import(
            '../views/activeCommunication/communicationEffect/analysisYearReport.vue'
          )
      },
      {
        path: '/highlyCitedArticles',
        name: 'highlyCitedArticles',
        component: () =>
          import('../views/contentAggregation/highlyCitedArticles.vue')
      },
      {
        path: '/priorityPublication',
        name: 'priorityPublication',
        component: () =>
          import('../views/contentAggregation/priorityPublication.vue')
      },
      {
        path: '/publishedJournals',
        name: 'publishedJournals',
        component: () =>
          import('../views/contentAggregation/publishedJournals/index.vue')
      },
      {
        path: '/publishedJournalsDetails',
        name: 'publishedJournalsDetails',
        component: () =>
          import(
            '../views/contentAggregation/publishedJournals/publishedJournalsDetails.vue'
          )
      },
      {
        path: '/academicInformation',
        name: 'academicInformation',
        component: () =>
          import('../views/informationAggregation/academicInformation.vue')
      },
      {
        path: '/conferenceInformation',
        name: 'conferenceInformation',
        component: () =>
          import('../views/informationAggregation/conferenceInformation.vue')
      },
      {
        path: '/citationTracking',
        name: 'citationTracking',
        component: () => import('../views/citationTracking/index.vue')
      },
      {
        path: '/downloadIssueReport',
        name: 'downloadIssueReport',
        component: () =>
          import('../views/downloadReport/downloadIssueReport.vue')
      },
      {
        path: '/downloadYearReport',
        name: 'downloadYearReport',
        component: () =>
          import('../views/downloadReport/downloadYearReport.vue')
      },
      {
        path: '/noData',
        name: 'noData',
        component: () => import('../views/noData.vue')
      }
    ]
  }
]

export default routes
