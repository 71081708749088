export default {
  name: 'manuscriptList',
  getList: {
    url: '/article/portalDocList.do'
    // url: '/article/docList.do'
  },
  upload: {
    url: '/article/articleUpload.do'
  },
  replace: {
    url: '/article/articleReplaceCheck.do'
  },
  jiaoCiList: {
    url: '/article/jiaoCiList.do'
  },
  publish: {
    url: '/article/articlePublish.do'
  },
  blindTrial: {
    url: '/article/blindTrial.do'
  },
  getPublisher: {
    url: '/article/getPublisher.do'
  },
  priorDownload: {
    url: '/article/priorDownload.do'
  },

  submitFirst: {
    // url: '/article/fistSubmit.do'
    url: '/article/portalFistSubmit.do'
  },
  widthDrawArticle: {
    url: '/article/undoArticle.do'
  },
  transfer: {
    url: '/article/portaltransfer.do'
  },
  getHandler: {
    url: '/article/getHandler.do'
  },
  getStatus: {
    url: '/article/sectionState.do'
  },
  groupPublication: {
    url: '/article/groupPublication.do'
  },
  magazineTemplate: {
    url: '/article/magazineTemplate.do'
  },
  journalYear: {
    url: '/magazine/journalYearList.do'
  },
  journalYearIssueList: {
    url: '/magazine/journalYearIssueList.do'
  },
  updateTemplate: {
    url: '/article/updateTemplate.do'
  },
  manage: {
    url: '/article/versionManage.do'
  },
  magazineDownload: {
    url: '/common/magazineDownload.do'
  },
  fileDownload: {
    url: '/common/download.do?file='
  },
  preadDownload: {
    url: '/article/exportreport.do'
  },
  journalYearIssueColList: {
    url: '/magazine/journalColList.do'
  },
  refreshArticle: {
    url: '/article/refreshArticle.do'
  },
  reStructured: {
    url: '/article/reStructured.do'
  },
  htmlDownload: {
    url: '/article/htmlDownload.do'
  },
  htmlShow: {
    url: '/article/htmlShow.do'
  },
  blindTrialRefresh: {
    url: '/article/blindTrialRefresh.do'
  },
  articleUploadCheck: {
    url: '/article/articleUploadCheck.do'
  },
  tuningCheck: {
    url: '/article/tuningCheck.do'
  },
  articleRePublish: {
    url: '/article/articleRePublish.do'
  },
  articleTemplate: {
    url: '/article/articleTemplate.do'
  },
  articleDownload: {
    url: '/article/articleDownload.do'
  },
  delete: {
    url: '/article/deleteArticle.do'
  },
  magazineVersionXmlDownload: {
    url: '/common/magazineVersionXmlDownload.do'
  },
  wechat: {
    url: '/article/wechat.do'
  },
  reTunMult: {
    url: '/article/reTunMult.do'
  },
  blindTrialDownload: {
    url: '/common/blindTrialDownload.do'
  },
  blindTrialCheck: {
    url: '/article/blindTrialCheck.do'
  },
  proofread: {
    url: '/article/proofread.do'
  },
  articleUploadFaild: {
    url: '/article/articleUploadFaild.do'
  },
  proofEdit: {
    url: '/article/wordClientStart.do'
  },
  cancelProof: {
    url: '/article/wordClientClose.do'
  },
  cancelNetpress: {
    url: '/article/fistCancel.do'
  },
  getAdvid: {
    url: 'index/login/getAdvid.do'
  },
  versionPriorityManage: {
    url: '/article/versionPriorityManage.do'
  },
  sendProof: {
    url: '/article/sendProof.do'
  },
  // 整期组刊送校样
  sendGroupProof: {
    url: '/book/sendProof.do'
  },
  // 记录用户刷新浏览器
  uploadFailedLog: {
    url: '/article/uploadFaildLog.do'
  },
  // 获取通讯作者
  onlineGetEmail: {
    url: '/article/onlineGetEmail.do'
  },
  // 发起审校
  onlineEditStart: {
    url: '/article/onlineEditStart.do'
  },
  jiaoDuiAddEmail: {
    url: '/article/jiaoDuiAddEmail.do'
  },
  jiaoDuiGetEmail: {
    url: '/article/jiaoDuiGetEmail.do'
  },
  // 邀请校稿
  onlineInvitation: {
    url: '/article/onlineInvitation.do'
  },
  // 邀请校稿获取默认人
  getOnlineInvitDefaultUser: {
    url: '/article/getOnlineInvitDefaultUser.do'
  },
  // 修改稿件编号
  articleUpdateField: {
    url: '/article/articleUpdateField.do'
  },
  usersByResources: {
    url: '/portal/usersByResources'
  },
  usermagazineresourcesbypid: {
    url: '/portal/usermagazineresourcesbypid'
  },
  getEmailTemplateByOrgMagazineId: {
    url: '/magazineMessage/getEmailTemplateByOrgMagazineId'
  },
  jiaoDuiOpinion: {
    url: '/article/jiaoDuiOpinion.do'
  },
  jiaoDuiOpinionSubmit: {
    url: '/article/jiaoDuiOpinionSubmit.do'
  },
  // 多格式
  multiCheck: {
    url: '/article/multiCheck.do'
  },
  multiSubmit: {
    url: '/article/multiSubmit.do'
  },
  multiList: {
    url: '/article/multiList.do'
  },
  multiRefresh: {
    url: '/multi/article/multiRefresh'
  }
}
