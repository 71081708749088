import './public-path'

import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import ElementUI from 'element-ui'
import App from './App.vue'
import routes from './router'
import stores from './store'
import './assets/css/common.scss'
import './assets/css/zzfw-common.scss'
Vue.use(ElementUI)

/**
 * 把共享的 vuex store 放到 kjqk-commons/src/common/micro-module.js
 * 以便于所有的子应用都能引用，并能单独启动项目
 */
// import commonModule from 'kjqk-commons/src/micro/store/micro-module.js'

// import * as micro from 'kjqk-commons/src/micro/index.js'
import commonModule from '@micro'
import * as micro from '@micro'
Vue.config.productionTip = false
Vue.prototype.$micro = micro

let router = null
let instance = null
let store = null

/**
 * 1. router的前缀
 * 2. 指定初始化vue实例的div容器
 * @param props
 */
function render(props = {}) {
  const { container, module: microModule, routeBase } = props

  // 初始化router
  router = new VueRouter({
    base: window.__POWERED_BY_QIANKUN__ ? routeBase : '/',
    mode: 'history',
    routes
  })

  // 初始化 vuex
  store = new Vuex.Store(stores)

  // 把公用的 微应用的vuex 也一起注册了
  store.registerModule('micro', {
    ...(microModule ? microModule : commonModule),
    namespaced: true
  })

  //初始化实例
  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
    async created() {
      await this.$micro.mock(this.$store)
      instance.$mount(container ? container.querySelector('#app') : '#app')
    }
  })
}

if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

/**
 * bootstrap 只会在微应用初始化的时候调用一次，下次微应用重新进入时会直接调用 mount 钩子，不会再重复触发 bootstrap。
 * 通常我们可以在这里做一些全局变量的初始化，比如不会在 unmount 阶段被销毁的应用级别的缓存等。
 */
export async function bootstrap() {
  console.log('[--- increment-service ---] bootstraped')
}

// 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
export async function mount(props) {
  render(props)
}

/**
 * 应用每次 切出/卸载 会调用的方法，通常在这里我们会卸载微应用的应用实例
 *
 */
export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
  router = null
  store = null
}
