export default {
  name: 'album',
  saveAlbum: {
    url: '/rc-pub/api/album/saveAlbum'
  },
  search: {
    url: '/rc-pub/api/album/search'
  },
  getinfo: {
    url: '/rc-pub/api/album/info'
  },
  isExist: {
    url: '/rc-pub/api/album/res/isExist'
  },
  editAlbum: {
    url: '/rc-pub/api/album/editAlbum'
  },
  // 获取专辑资源列表
  searchdetailslist: {
    url: '/rc-pub/api/album/res/search'
  },
  // 发布-获取专辑资源列表
  getExpertList: {
    url: '/rc-pub/api/album/res/expert/search'
  },
  docLibIdlists(docLibId) {
    return `/publishtaitenantIdpubid/rc-api/index/${docLibId}/lists`
  },
  saveAlbumRes: {
    url: '/rc-pub/api/album/res/saveAlbumRes'
  },
  sortAlbumRes: {
    url: '/rc-pub/api/album/res/sortAlbumRes'
  },
  deleteAlbumRes: {
    url: '/rc-pub/api/album/res/delete'
  },
  getTenantInfo: {
    url: '/rc-pub/api/site/getTenantInfo'
  },
  intakeAlbumList: {
    url: '/rc-pub/api/album/intakeAlbumList'
  },
  intakeAlbumRes: {
    url: '/rc-pub/api/album/res/intakeAlbumRes'
  },
  getDocIdsByAlbumId: {
    url: '/rc-pub/api/album/getDocIdsByAlbumId'
  },
  getSiteIdByTenantIdAndPublicationId: {
    url: '/rc-pub/api/rc-publication/getSiteIdByTenantIdAndPublicationId'
  },
  getArticlesByPeriodicalIdGroupByColumn(id) {
    return `/rc-pub/front/front-article/getArticlesByPeriodicalIdGroupByColumn/${id}`
  },
  savePeriodId: {
    url: '/rc-pub/api/album/res/savePeriodId'
  },
  // 虚拟专辑查询资源列表
  getAlbumData: {
    url: '/rc-pub/api/album/searchSource'
  },
  // 资源图片分页查询
  getPictureData: {
    url: '/rc-pub/api/picture/page'
  },
  // 资源表格分页查询
  getTableData: {
    url: '/rc-pub/api/table/page'
  },
  // 添加资源查询专家列表
  getProfessorData: {
    url: '/rc-pub/api/author/search'
  },
  // 发布添加资源查询专家列表
  getAddExpert: {
    url: '/rc-pub/api/pd-expert/album/res/search'
  },
  // 下载虚拟专辑附件
  downloadAttach(attachId) {
    return '/rc-pub/api/files/download/' + attachId + '/'
  },
  // 根据专辑id获取专辑栏目列表
  getPartList: {
    url: '/rc-pub/api/album/part/getPartList'
  },
  // 保存专辑栏目
  partSave: {
    url: '/rc-pub/api/album/part/save'
  },
  // 编辑专辑栏目
  partEdit: {
    url: '/rc-pub/api/album/part/edit'
  },
  // 修改专辑栏目启禁用状态
  partStatus: {
    url: '/rc-pub/api/album/part/switchStatus'
  },
  // 专辑栏目上下移操作
  partSort: {
    url: '/rc-pub/api/album/part/sort'
  },
  // 获取专辑栏目详情
  partInfo: {
    url: '/rc-pub/api/album/part/info'
  },
  // 删除栏目
  deletePart: {
    url: '/rc-pub/api/album/part/delete'
  }
}
