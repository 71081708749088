import createAPI from '../middleware/createAPI'
const _createAPI = function (url, ...config) {
  return createAPI('/je-api/journal-edit-reviewer' + url, ...config)
}
export default {
  // 站内信列表
  getMessageInsideList: (config) =>
    _createAPI('/v1/review/message-inside/list', 'get', config),
  // 站内信删除
  deleteMessageInside: (ids, config) =>
    _createAPI(`/v1/review/message-inside/${ids}`, 'delete', config),
  // 站内信删除
  getMessageInsideUnreadCount: (config) =>
    _createAPI(`/v1/review/message-inside/unread/count`, 'get', config),
  // 全部已读
  postMessageInsideUnreadClear: (config) =>
    _createAPI(`/v1/review/message-inside/unread/clear`, 'post', config),
  // 单条已读
  postMessageInsideRead: (config) =>
    _createAPI(`/v1/review/message-inside/read`, 'post', config)
}
