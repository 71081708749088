export default {
  name: 'reviewsetting',
  articleTemplate: {
    url: '/magazine/gettemplatelist'
  },
  reviewsetting: {
    url: '/magazine/saveorupdaterevisionsetting'
  },
  getrevisionsettinginfo: {
    url: '/magazine/getrevisionsettinginfo'
  }
}
