<template>
  <div class="layout-components">
    <div class="content">
      <div class="side" :class="ISCOLLAPSE ? 'hideSidebar' : 'openSidebar'">
        <left-bar />
      </div>
      <div
        class="view-content main-content"
        :class="ISCOLLAPSE ? 'hideSidebar' : 'openSidebar'"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import leftBar from '../layouts/LeftBar'
import { mapState } from 'vuex'
export default {
  name: 'Layout',
  components: {
    leftBar
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('micro/sidebar', { ISCOLLAPSE: 'isCollapse' })
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="scss">
.layout-components {
  position: relative;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  .content {
    $side-width: 190px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: left;
    height: calc(100vh - 70px);
    .side,
    .view-content {
      position: absolute;
      top: 0;
      bottom: 0;
      overflow-y: auto;
    }
    .side {
      left: 0;
      overflow: hidden;
      &.hideSidebar {
        width: 60px;
      }
      &.openSidebar {
        width: $side-width;
      }
    }
    .view-content {
      right: 0;
      padding-right: 0;
      overflow: auto;
      &.hideSidebar {
        left: 60px;
      }
      &.openSidebar {
        left: $side-width - 1px;
      }
    }
  }
}
</style>
