/**
 * 把共享的 vuex store 放到 kjqk-commons/src/common/micro-module.js
 * 以便于所有的子应用都能引用，并能单独启动项目
 */

import auth from './modules/auth'
import user from './modules/user'
import magazine from './modules/magazine'
import message from './modules/message'
import sidebar from './modules/sidebar'
import AIChat from './modules/ai-chat'
import wechat from './modules/wechat'
import jquery from 'jquery'

export const microModule = {
  namespaced: true,
  modules: {
    auth,
    user,
    magazine,
    message,
    sidebar,
    AIChat,
    wechat
  },
  state: {
    menuActive: '',
    loading: true,
    jquery
  },
  getters: {
    LOADING: (state) => state.loading
  },
  mutations: {
    getMenuActive(state, payload) {
      state.menuActive = payload
    },
    UPDATE_LOADING(state, payload) {
      state.loading = payload
    }
  }
}
