export default {
  name: 'columnManage1',
  // 作者列表
  pageList: {
    url: '/rc-pub/api/topic/res/search'
  },
  getChannelList: {
    url: '/rc-pub/api/channel/getChannelList'
  },
  getSiteId: {
    url: '/rc-pub/api/pub/getSiteId'
  },
  // 新版获取siteId
  getSiteIdByMagId: {
    url: '/rc-pub/api/pub/getSiteIdByMagId'
  },
  fileUpload: {
    url: '/rc-pub/api/files/fileUpload'
  },
  // 添加排行榜
  addRank: {
    url: '/rc-pub/api/rank/addRank'
  },
  // 添加快捷入口
  addQuick: {
    url: '/rc-pub/api/quick/addQuick'
  },
  addCarousel: {
    url: '/rc-pub/api/carousel/addCarousel'
  },
  // 删除接口
  deleteList: {
    url: '/rc-pub/api/topic/res/deleteList'
  },
  // 编辑时获取资源信息
  getinfo: {
    url: '/rc-pub/api/topic/res/info'
  },
  // 轮播图编辑
  editCarousel: {
    url: '/rc-pub/api/carousel/editCarousel'
  },
  // 禁用启用接口
  switchResStatus: {
    url: '/rc-pub/api/topic/res/switchResStatus'
  },
  // 编辑排行榜
  editRank: {
    url: '/rc-pub/api/rank/editRank'
  },
  // 编辑快捷入口
  editQuick: {
    url: '/rc-pub/api/quick/editQuick'
  },
  // 资讯查询
  pageBySiteIdAndTitle: {
    url: '/rc-pub/api/rc-news/pageBySiteIdAndTitle'
  },
  // 添加资讯
  resAdd: {
    url: '/rc-pub/api/topic/res/add'
  },
  // 获取资讯信息接口
  // getRes (id) {
  //   return `/rc-pub/api/rc-news/${id}`
  // },
  // 编辑资讯
  resEdit: {
    url: '/rc-pub/api/topic/res/edit'
  },
  // 名称获取论文接口
  getArticlesByName: {
    url: '/rc-pub/api/rc-article/getItems'
  },
  // 添加图文信息
  addItem: {
    url: '/rc-pub/api/item/addItem'
  },
  // 获取频道
  getChannelByType: {
    url: '/rc-pub/api/channel/getChannelByType'
  },
  // 快捷入口获取频道
  getQuickChannel: {
    url: '/rc-pub/api/channel/getQuickChannel'
  },
  journalList: {
    url: '/magazine/journalPublishList.do' // 获取刊物（韩桂林）
  },
  getSubCats: {
    url: '/aif/rc-api/common/categories'
  },
  // 编委会列表
  expertSearch: {
    url: '/rc-pub/api/expert/search'
  },
  // 编委会编辑info
  expertInfo: {
    url: '/rc-pub/api/expert/get'
  },
  // 编辑编委会
  expertEdit: {
    url: '/rc-pub/api/expert/edit'
  },
  // 添加编委会
  expertAdd: {
    url: '/rc-pub/api/expert/add'
  },
  sortExpert: {
    url: '/rc-pub/api/expert/sortExpert'
  },
  // 启/禁编委会
  expertSwitchStatus: {
    url: '/rc-pub/api/expert/switchStatus'
  },
  // 删除编委会
  expertDelete: {
    url: '/rc-pub/api/expert/delete'
  },
  // 导入编委会
  importExpert: {
    url: '/rc-pub/api/expert/importExpert'
  },
  // 编委会错误信息下载
  downErrorExcel: {
    url: '/rc-pub/api/expert/downErrorExcel'
  },
  // 获取编辑器内容信息
  getInfo: {
    url: '/rc-pub/api/item/info'
  },
  // 编辑器编辑接口
  editItem: {
    url: '/rc-pub/api/item/editItem'
  },
  // 启用编辑器
  startUseEditor: {
    url: '/rc-pub/api/item/switchStatus'
  },
  // 自定义频道-获取频道当前id频道信息
  channelInfo: {
    url: '/rc-pub/api/channel/info'
  },
  // 自定义频道-查询模板列表（下拉选项集合）
  getTemplateList: {
    url: '/rc-pub/api/channel/templates'
  },
  // 保存模板接口
  saveTemplate(channelId) {
    return `/rc-pub/api/channel/${channelId}/templates`
  },
  // 自定义频道-添加
  saveChannel: {
    url: '/rc-pub/api/channel/saveChannel'
  },
  // 自定义频道-编辑
  updateChannel: {
    url: '/rc-pub/api/channel/updateChannel'
  },
  // 自定义频道-启用、禁用
  switchChannelStatus: {
    url: '/rc-pub/api/channel/switchChannelStatus'
  },
  // 自定义频道-上移、下移、升级、降级
  sortChannel: {
    url: '/rc-pub/api/channel/sortChannel'
  },
  // 列表上移、下移
  sortList: {
    url: '/rc-pub/api/topic/res/sort'
  },
  // 自定义频道-删除
  deleteChannel: {
    url: '/rc-pub/api/channel/deleteChannel'
  },
  // 统计echarts数据
  getCharts: {
    url: '/rc-pub/api/statistics/charts'
  },
  // 统计table数据
  statisticList: {
    url: '/rc-pub/api/statistics/report'
  },
  // 统计echarts数据
  statisticExport: {
    url: '/rc-pub/api/statistics/export'
  },
  // 获取频道关系
  getRelatedChannel: {
    url: '/rc-pub/api/channel/getRelatedChannel'
  },
  // 编辑频道名称
  editRelatedChannel: {
    url: '/rc-pub/api/channel/editRelatedChannel'
  },
  // 虚拟专辑
  // 新建期刊是否存在
  isExist: {
    url: '/rc-api/periodicals/isExist'
  },
  // 上架、下架、发布
  albumSwitchStatus: {
    url: '/rc-pub/api/album/switchStatus'
  },
  checkPlayAuth: {
    url: '/rc-pub/api/topic/res/checkPlayAuth'
  },
  // 删除专辑
  albumDelete: {
    url: '/rc-pub/api/album/delete'
  },
  // 前往发布平台前台
  getAddress: {
    url: '/rc-pub/api/site/getAddress'
  },
  // 自定义频道论文类检索
  getArticleSearchRes: {
    url: '/rc-pub/api/rc-article/searchRes'
  },
  // 专题资源保存
  addListRes: {
    url: '/rc-pub/api/topic/res/addList'
  },
  // 添加资讯列表
  getAcademicInfoSearchRes: {
    url: '/rc-pub/api/rc-news/searchRes'
  },
  // 添加专辑列表
  getAlbumInfoSearchRes: {
    url: '/rc-pub/api/album/search'
  },
  // 下架专辑提示
  getisInUseApi: {
    url: '/rc-pub/api/album/isInUse'
  },
  // 轮播图列表检索、组图实时检索、组图编辑时列表
  getCarouselSearch: {
    url: '/rc-pub/api/carousel/search'
  },
  // 图书管理列表
  getBookList: {
    url: '/rc-pub/api/rc-book/pageBySiteIdAndTitle'
  },
  // 资源管理下的图书管理列表
  getResBookList: {
    url: '/rc-api/book'
  },
  // 资源管理下创建图书
  bookResBookSave: {
    url: '/rc-api/book/save'
  },
  // 创建图书
  bookSave: {
    url: '/rc-pub/api/rc-book/save'
  },
  // 导入
  importBook: {
    url: '/rc-pub/api/rc-book/importBook'
  },
  // 导出
  exportBook: {
    url: '/rc-pub/api/rc-book/exportBook'
  },
  // 整期导入
  importPeriodArticles: {
    url: '/rc-pub/api/rc-article/importPeriodArticles'
  },
  // 优先导入
  importFirstArticles: {
    url: '/rc-pub/api/rc-article/importFirstArticles'
  },
  // 优先整期导入
  importArticles: {
    url: '/rc-pub/api/rc-article/importArticles'
  },
  // 优先整期导入验证
  checkProdbImportError: {
    url: '/rc-pub/api/rc-article/checkProdbImportError'
  },
  // 优先整期导入失败下载
  exportProdbImportError: {
    url: '/rc-pub/api/rc-article/exportProdbImportError'
  },
  // 整期导出
  exportPeriodArticlesById: {
    url: '/rc-pub/api/rc-article/exportPeriodArticlesById'
  },
  // 优先导出
  exportFirstArticles: {
    url: '/rc-pub/api/rc-article/exportFirstArticles'
  },
  // 删除图书
  deleteBook: {
    url: '/rc-pub/api/rc-book/delete'
  },
  // 编辑图书
  editBook: {
    url: '/rc-pub/api/rc-book/edit'
  },
  // 下架图书
  bookPullOffShelves: {
    url: '/rc-pub/api/rc-book/pullOffShelves'
  },
  // 发布图书
  bookPutOnShelves: {
    url: '/rc-pub/api/rc-book/putOnShelves'
  },
  // 图书信息
  bookInfo(id) {
    return `/rc-pub/api/rc-book/getBookInfo/${id}`
  },
  // 删除资源
  deleteSource(docLibId) {
    return `/rc-api/common/deleteDocs/${docLibId}`
  },
  // 资源管理图书信息
  resourceBookInfo(docId, docLibId) {
    return `/rc-api/common/${docLibId}/${docId}`
  },
  // 轮播图组图上移下移
  sortGroupCarousel: {
    url: '/rc-pub/api/carousel/sort'
  },
  // SEO设置
  setSearchKeyWords: {
    url: '/rc-pub/api/site/setSearchKeyWords'
  },
  // seo优化，获取首页资讯页等页面标题关键词描述等
  getSeoTdk: {
    url: '/rc-pub/api/config-seo/findBySiteId'
  },
  // seo优化，首页资讯页等页面标题关键词描述等
  setSeoTdk: {
    url: '/rc-pub/api/config-seo/save'
  },
  // seo优化，上传robots、sitemap文件
  uploadSeoFile(params) {
    return `/rc-pub/api/files/fileUploadToPath${params}`
  },
  // 获取站点信息
  getSiteInfo: {
    url: '/rc-pub/api/site/info'
  },
  getUploadLocation: {
    url: '/rc-pub/api/layout/getLayout'
  },
  // 更新颜色
  updateColour: {
    url: '/rc-pub/api/site/updateColour'
  },
  getLayout: {
    url: '/rc-pub/api/layout/list'
  },
  setLayout: {
    url: '/rc-pub//api/site/setLayout'
  },
  // 添加自定义视频资源
  configAddVideo: {
    url: '/rc-pub/api/video/topicSearch'
  },
  // 模块列表接口
  getModuleList: {
    url: '/rc-pub/api/module/getModuleList'
  },
  // 模块移动接口
  sortModule: {
    url: '/rc-pub/api/module/sortModule'
  },
  // 模块启禁用
  switchModuleStatus: {
    url: '/rc-pub/api/module/switchModuleStatus'
  },
  saveModule: {
    url: '/rc-pub/api/module/saveModule'
  },
  // 获取模块信息
  getModuleInfo: {
    url: '/rc-pub/api/module/info'
  },
  // 编辑模块
  updateModule: {
    url: '/rc-pub/api/module/updateModule'
  },
  // 删除模块信息
  deleteModuleApi: {
    url: '/rc-pub/api/module/deleteModule'
  },
  // 批量编辑模块
  editModuleList: {
    url: '/rc-pub/api/module/editModuleList'
  },
  // 顶刊专辑搜索
  topIssueSearch: {
    url: '/rc-pub/api/topic/res/search'
  },
  // 一键置顶及取消置顶
  toTop: {
    url: '/rc-pub/api/topic/res/toTop'
  },
  // 一键推荐及取消推荐
  toRecommend: {
    url: '/rc-pub/api/topic/res/recommendOrNot'
  },
  // 微信文章添加
  saveWechat: {
    // url: '/rc-pub/api/wechatArticle/save'
    url: '/rc-pub/api/rc-news/save'
  },
  // 微信文章列表
  wechatArticleList: {
    // url: '/rc-pub/api/wechatArticle/getWechatArticleList'
    url: '/rc-pub/api/rc-news/pageBySiteIdAndTitle'
  },
  // 会议列表
  getMeetingList: {
    url: '/rc-pub/api/meeting/getMeetingList'
  },
  // 保存分类
  typeSaveApi: {
    url: '/rc-pub/api/rc-news/wechat-news-type'
  },
  // 获取分类列表
  getCatListApi: {
    url: '/rc-pub/api/rc-news/getNewsTypeList'
  },
  // 批量上架微信文章
  wechatPutOnShelves: {
    url: '/rc-pub/api/rc-news/putOnShelves'
  },
  // 获取微信文章下架之前提示信息
  pullOffShelvesMessage: {
    url: '/rc-pub/api/wechatArticle/pullOffShelvesMessage'
  },
  // 批量下架微信文章
  wechatPullOffShelves: {
    // url: '/rc-pub/api/wechatArticle/pullOffShelves'
    url: '/rc-pub/api/rc-news/pullOffShelves'
  },
  // 批量上架会议
  meetingPutOnShelves: {
    url: '/rc-pub/api/meeting/putOnShelves'
  },
  // 获取会议下架之前提示信息
  meetingPullOffShelvesMessage: {
    url: '/rc-pub/api/meeting/pullOffShelvesMessage'
  },
  // 批量下架会议
  meetingPullOffShelves: {
    url: '/rc-pub/api/meeting/pullOffShelves'
  },
  // 批量删除微信文章
  wechatDelete: {
    url: '/rc-pub/api/rc-news/delete'
  },
  // 批量删除会议
  meetingDelete: {
    url: '/rc-pub/api/meeting/delete'
  },
  // 获取微信文章基本信息
  getWechatArticleInfo(id) {
    // return `/rc-pub/api/wechatArticle/getWechatArticleInfo/${id}`
    return `/rc-pub/api/rc-news/${id}`
  },
  // 获取会议基本信息
  getMeetingInfo(id) {
    return `/rc-pub/api/meeting/getMeetingInfo/${id}`
  },
  // 微信文章添加
  saveMeeting: {
    url: '/rc-pub/api/meeting/save'
  },
  // 专辑添加资源时候，微信资源检索
  searchWechatArticleRes: {
    url: '/rc-pub/api/topic/res/searchWechatArticleRes'
  },
  // 专辑添加资源时候，会议资源检索
  searchPdMeetingRes: {
    url: '/rc-pub/api/topic/res/searchPdMeetingRes'
  },
  // 添加自定义图片资源
  configAddPicture: {
    url: '/rc-pub/api/topic/res/searchPictureRes'
  },
  // 添加自定义精选期刊资源
  configAddSelMag: {
    url: '/rc-pub/api/topic/res/searchPublicationRes'
  },
  // 添加自定义音频资源
  configAddAudio: {
    url: '/rc-pub/api/topic/res/searchAudioRes'
  },
  // 添加自定义表格资源
  configAddTable: {
    url: '/rc-pub/api/topic/res/searchTableRes'
  },
  // 添加自定义科研数据表格资源
  configAddScienceData: {
    url: '/rc-pub/api/topic/res/searchScienceRes'
  },
  getSiteAttach: {
    url: '/rc-pub/api/siteAttach/findBySiteId'
  },
  addAttach: {
    url: '/rc-pub/api/siteAttach/add'
  },
  editAttach: {
    url: '/rc-pub/api/siteAttach/edit'
  },
  deleteAttach: {
    url: '/rc-pub/api/siteAttach/delete'
  },
  getAttachInfo: {
    url: '/rc-pub/api/siteAttach/info'
  },
  // 期刊列表类
  searchTopicRes: {
    url: '/rc-pub/api/topic/res/search'
  },
  // 期刊列表类
  searchPublicationRes: {
    url: '/rc-pub/api/topic/res/searchPublicationRes'
  },
  searchBookRes: {
    url: '/rc-pub/api/topic/res/searchBookRes'
  },
  // 根据组件码获取组件样式列表
  findByCode: {
    url: `/rc-pub/api/component/findByCode`
  },
  // 模块升降级操作
  changeLevel: {
    url: `/rc-pub/api/module/changeLevel`
  },
  // 根据频道id和code值获取模块列表
  getSpecificModuleList: {
    url: `/rc-pub/api/module/getSpecificModuleList`
  },
  // 资讯导入
  importNews: {
    url: `/rc-pub/api/rc-news/importNews`
  },
  // 资讯导出
  exportNews: {
    url: `/rc-pub/api/rc-news/exportNews`
  },
  // 工具栏
  getToolsList: {
    url: '/rc-pub/api/tools/getToolsList'
  },
  // 添加快捷入口
  addTools: {
    url: '/rc-pub/api/tools/save'
  },
  // 获取工具集详情
  getToolInfo: {
    url: '/rc-pub/api/tools/info'
  },
  // 编辑工具集
  editToolInfo: {
    url: '/rc-pub/api/tools/edit'
  },
  // 启用、禁用工具集
  toolsSwitchStatus: {
    url: '/rc-pub/api/tools/switchStatus'
  },
  // 工具集删除接口
  deleteTools: {
    url: '/rc-pub/api/tools/delete'
  },
  // 工具集升降序
  sortTools: {
    url: '/rc-pub/api/tools/sort'
  },
  // 获取移动端栏目list
  getMobileModuleList: {
    url: '/rc-pub/api/module/getMobileModuleList'
  },
  // 移动端模块移动接口
  sortMobileModule: {
    url: '/rc-pub/api/module/sortMobileModule'
  },
  // 是否显示移动端模块
  switchMobileModuleStatus: {
    url: '/rc-pub/api/module/switchMobileModuleStatus'
  },
  // 根据论文ID查询补充资料
  getAdditionalMaterials: {
    url: '/rc-pub/api/rc-article/getAdditionalMaterials'
  },
  // 新增论文补充资料
  saveAdditionalMaterials: {
    url: '/rc-pub/api/rc-article/saveAdditionalMaterials'
  },
  // 根据类型和索引id获取栏目列表
  partList: {
    url: '/rc-pub/api/part/list'
  },
  // 添加栏目
  addPart: {
    url: '/rc-pub/api/part/addPart'
  },
  // 添加栏目
  editPart: {
    url: '/rc-pub/api/part/edit'
  },
  // 栏目详情
  infoPart: {
    url: '/rc-pub/api/part/info'
  },
  // 栏目删除
  deletePart: {
    url: '/rc-pub/api/part/delete'
  },
  // 启用禁用栏目
  switchStatusPart: {
    url: '/rc-pub/api/part/switchStatus'
  },
  // 启用禁用栏目
  sortPart: {
    url: '/rc-pub/api/part/sort'
  },
  // 获取资讯的数据类型
  getNewsDataType: {
    url: '/rc-pub/api/dictionaries/news-data-types'
  },
  getJumpUrl: {
    url: '/rc-pub/front/front-news/getJumpUrl'
  },
  // 期刊简介设置
  saveMagIntro: {
    url: '/rc-pub/api/item/saveMagIntro'
  },
  getMagIntro: {
    url: '/rc-pub/api/item/getMagIntro'
  },
  uploadHtml: {
    url: '/rc-pub/api/files/uploadHtml '
  },
  // 导入图书
  importBookOfResource: {
    url: '/rc-api/book/importBook'
  },
  // 导出图书
  exportBookOfResource: {
    url: '/rc-api/book/exportBook'
  },
  // 图书错误信息下载
  downErrorExcelOfResource: {
    url: '/rc-api/book/downErrorExcel'
  }
}
