export default {
  name: 'emailPush',
  // 邮件传播
  GetMagByPlatMagId: {
    url: '/das-api/vas/mag/getMagByPlatMagId'
  },
  GetPushTaskList: {
    url: '/das-api/vas/push/task/pushTaskList'
  },
  GetTaskInfo: {
    url: '/das-api/vas/push/task/getTaskInfo'
  },
  GetAuthorRankList: {
    url: '/das-api/vas/push/task/authorRankList'
  },
  GetDiffusePathList: {
    url: '/das-api/vas/push/task/diffusePath'
  },
  GetStatisticsByDay: {
    url: '/das-api/vas/push/task/statisticsByDay'
  },
  GetAllTaskInfo: {
    url: '/das-api/vas/push/task/getAllTaskInfo'
  },
  GetMagDetail: {
    url: '/das-api/vas/mag/get'
  },
  // 邮件推送-报告部分
  // 邮件推送-官网访问
  GetPushArticleSumVisitCount: {
    url: '/das-api/vas/article/statistics/getPushArticleSumVisitCount'
  },
  // 激活学者所在机构
  GetActivatedOrg: {
    url: '/das-api/vas/send/author/getActivatedOrg'
  },
  // 邮件推送-推送结果分析
  // 推送前后单篇文章阅读情况
  GetPushArticleVisitCount: {
    url: '/das-api/vas/article/statistics/getPushArticleVisitCount'
  },
  GetArticleClickList: {
    url: '/das-api/vas/push/task/articleClickRankList'
  },
  GetArticleOfficialClickList: {
    url: '/das-api/vas/push/task/articleOfficialClickRankList'
  },
  // 阅读文章用户的机构分布
  GetReadArticleOrg: {
    url: '/das-api/vas/send/author/getReadArticleOrg'
  },
  // 阅读文章用户的期刊分布
  GetReadArticleMag: {
    url: '/das-api/vas/send/author/getReadArticleMag'
  },
  // 阅读邮件用户的机构分布
  GetOpenEmailOrg: {
    url: '/das-api/vas/send/author/getOpenEmailOrg'
  },
  // 阅读邮件用户的期刊分布
  GetOpenEmailMag: {
    url: '/das-api/vas/send/author/getOpenEmailMag'
  },
  // 邮件推送-推送用户数据
  // 推送用户概况
  GetSum: {
    url: '/das-api/vas/send/author/getSum'
  },
  // 推送用户的来源分布
  GetSourceType: {
    url: '/das-api/vas/send/author/getSourceType'
  },
  // 推送用户的机构分布
  GetPushMagAuthorOrg: {
    url: '/das-api/vas/send/author/getOrg'
  },
  // 推送用户的期刊分布
  GetMag: {
    url: '/das-api/vas/send/author/getMag'
  },
  // 邮件推送-白名单推送分析
  // 白名单总统计信息
  GetSumWhiteListAuthor: {
    url: '/das-api/vas/send/author/getSumWhiteListAuthor'
  },
  // 白名单用户活跃排行
  GetWhiteListAuthorSumList: {
    url: '/das-api/vas/send/author/getWhiteListAuthorSumList'
  },
  // 邮件推送-期刊画像
  // 引用期刊分布
  ReferenceMag: {
    url: '/das-api/vas/statistics/referenceMag'
  },
  // 引证期刊分布
  CitationMag: {
    url: '/das-api/vas/statistics/citationMag'
  },
  // 引用机构分布
  ReferenceInstitution: {
    url: '/das-api/vas/statistics/referenceInstitution'
  },
  // 引证机构分布
  CitationInstitution: {
    url: '/das-api/vas/statistics/citationInstitution'
  },
  // 机构发文统计
  Institution: {
    url: '/das-api/vas/statistics/institution'
  },
  // 学者活跃度排行
  GetAuthorSumList: {
    url: '/das-api/vas/send/author/getAuthorSumList'
  },
  // 关键词统计
  GetKeywordsStatistics: {
    url: '/das-api/vas/statistics/getKeywordsStatistics'
  },
  // 共现词统计
  GetCoocwordsStatistics: {
    url: '/das-api/vas/statistics/getCoocwordsStatistics'
  },
  // 同领域期刊
  ZxsMag: {
    url: '/das-api/vas/statistics/zxsMag'
  },
  // 共现词统计
  ZxsInstitution: {
    url: '/das-api/vas/statistics/zxsInstitution'
  },
  // 推送任务推送进度状态
  GetPushStatus: {
    url: '/das-api/vas/push/task/getPushStatus'
  },
  //邮件推送-学者数据测评-整期论文引证分页统计
  GetIssueArticleCitation: {
    url: '/das-api/vas/article/getIssueArticleCitation'
  },
  //邮件推送-学者数据测评-整期论文引证数量统计
  SumIssueArticleCitation: {
    url: '/das-api/vas/article/sumIssueArticleCitation'
  },
  //邮件推送-学者数据测评-被引文献
  GetCountRootArticleCitations: {
    url: '/das-api/vas/article/countRootArticleCitations'
  },
  //邮件推送-学者数据测评-引证期刊
  GetCountMagCitations: {
    url: '/das-api/vas/article/countMagCitations'
  },
  //邮件推送-学者数据测评-被引机构
  GetCountRootMagOrg: {
    url: '/das-api/vas/article/countRootMagOrg'
  },
  //邮件推送-学者数据测评-引证机构
  GetCountCitationsMagOrg: {
    url: '/das-api/vas/article/countCitationsMagOrg'
  },
  //邮件推送-学者数据测评-被引文献关键词
  GetCountRootArticleKeywords: {
    url: '/das-api/vas/article/countRootArticleKeywords'
  },
  //邮件推送-学者数据测评-引证文献关键词
  GetCountCitatinsArticleKeywords: {
    url: '/das-api/vas/article/countCitatinsArticleKeywords'
  },
  //引证追踪-获取关注二维码
  getWechatPicAddress: {
    url: '/das-api/vas/wechat/wechatPicAddress'
  }
}
