var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cus-sidebar-item"},[(_vm.item.single)?_c('el-menu-item',{class:_vm.activeIndex == _vm.item.index ? 'is-active-cus' : '',attrs:{"index":_vm.item.index,"id":_vm.item.idText},nativeOn:{"click":function($event){return _vm.menuClick(_vm.item)}}},[_c('div',{attrs:{"title":_vm.ISCOLLAPSE ? _vm.item.columnName : ''}},[_c('img',{staticClass:"menu-icon",attrs:{"src":_vm.activeIndex == _vm.item.index ? _vm.item.activeIcon : _vm.item.icon}}),_c('span',{staticClass:"menu-title"},[_vm._v(_vm._s(_vm.item.columnName))]),(_vm.item.childrenList && _vm.item.childrenList.length > 0)?_c('img',{staticClass:"menu-icon-arrow",attrs:{"src":_vm.activeIndex == _vm.item.index ? _vm.iconArrowActive : _vm.iconArrow}}):_vm._e()])]):_c('el-submenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.childrenList.length > 0),expression:"item.childrenList.length > 0"}],class:{
      'is-active-cus2': _vm.activeIndex == _vm.item.index,
      'is-collapse': _vm.ISCOLLAPSE,
      'defined-menu': _vm.item.index == '5'
    },attrs:{"index":_vm.item.index,"id":_vm.item.idText}},[_c('template',{slot:"title"},[_c('img',{staticClass:"menu-icon",attrs:{"src":_vm.activeIndex == _vm.item.index ? _vm.item.activeIcon : _vm.item.icon}}),_c('span',{staticClass:"menu-title"},[_vm._v(_vm._s(_vm.item.title))]),_c('img',{staticClass:"menu-icon-arrow",attrs:{"src":_vm.activeIndex == _vm.item.index ? _vm.iconArrowActive : _vm.iconArrow}})]),_vm._l((_vm.item.childrenList),function(child){return _c('div',{key:child.columnId,staticClass:"sub-child-menu sub-child-item"},[(child.childrenList && child.childrenList.length > 0)?_c('el-submenu',{staticClass:"defined-el-submenu",attrs:{"index":child.index,"id":child.idText}},[_c('span',{staticClass:"defined-title",class:{
            'is-active-title': _vm.subActiveIndex == child.index
          },attrs:{"slot":"title"},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.menuClick(child)}},slot:"title"},[_c('span',{staticClass:"column-name"},[_vm._v(_vm._s(child.columnName))]),(_vm.isCount)?_c('span',{staticClass:"count-number"},[_vm._v(" "+_vm._s(child.count == 0 || child.count ? `(${child.count})` : '')+" ")]):_vm._e()]),_vm._l((child.childrenList),function(subChild){return _c('el-menu-item',{key:subChild.columnId,attrs:{"index":subChild.index}},[_c('span',{staticClass:"sub-defined-title",class:{
              'is-active-title': _vm.subActiveIndex == subChild.index
            },on:{"click":function($event){return _vm.menuClick(subChild)}}},[_c('span',{staticClass:"column-name"},[_vm._v(_vm._s(subChild.columnName))]),(_vm.isCount)?_c('span',{staticClass:"count-number"},[_vm._v(" "+_vm._s(subChild.count == 0 || subChild.count ? `(${subChild.count})` : '')+" ")]):_vm._e()])])})],2):_c('el-menu-item',{class:_vm.subActiveIndex == child.index ? 'is-active-cus' : '',attrs:{"index":child.index,"id":child.idText},on:{"click":function($event){return _vm.menuClick(child)}}},[_c('span',{staticClass:"column-name"},[_vm._v(_vm._s(child.columnName))]),(_vm.isCount)?_c('span',{staticClass:"count-number"},[_vm._v(" "+_vm._s(child.count == 0 || child.count ? `(${child.count})` : '')+" ")]):_vm._e()])],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }