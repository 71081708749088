export default {
  name: 'login',
  captcha: {
    url: '/index/login/captcha.do'
  },
  login: {
    url: '/index/login/login.do'
  },
  isshowcaptcha: {
    url: '/index/login/isshowcaptcha.do'
  },
  loginCode: {
    url: '/index/login/loginCode.do'
  }
}
