export default {
  name: 'journalOverview',
  // 期刊数据概况统计
  getMagOverView: {
    url: '/rc-pub/api/rc-publication/statistics/getMagOverView'
  },
  // 网站概况-引证统计-查询地址
  getCitation: {
    url: '/rc-pub/api/rc-publication/statistics/citation'
  },
  // 网站概况-引证统计-导出地址
  exportCitation: {
    url: '/rc-pub/api/rc-publication/statistics/exportCitation'
  },
  // 网站概况-引用统计-查询地址
  getReference: {
    url: '/rc-pub/api/rc-publication/statistics/reference'
  },
  // 网站概况-引用统计-导出地址
  exportReference: {
    url: '/rc-pub/api/rc-publication/statistics/exportReference'
  },
  // 网站概况-机构发文统计-查询地址
  getInstitution: {
    url: '/rc-pub/api/rc-publication/statistics/institution'
  },
  // 网站概况-机构发文统计-导出地址
  exportInstitution: {
    url: '/rc-pub/api/rc-publication/statistics/exportInstitution'
  },
  // 网站概况-论文引证统计-查询地址
  getArticle: {
    url: '/rc-pub/api/rc-publication/statistics/article'
  },
  // 网站概况-论文引证统计-导出地址
  exportArticle: {
    url: '/rc-pub/api/rc-publication/statistics/exportArticle'
  }
}
