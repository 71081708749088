/**
 * 为了能让微应用单独启动 开发，写个mock方法，自动登录
 * @param $store
 * @returns {Promise<void>}
 */
export async function mock($store) {
  // 如果是开发环境，并且没有用乾坤（或者说是单独启动应用的时候），那么需要初始化一些数据，来模拟登陆以及获取magazineId等操作
  if (process.env.NODE_ENV !== 'production' && !window.__POWERED_BY_QIANKUN__) {
    await getMagazineId($store)
  }
}

async function getMagazineId($store) {
  // 获取右上角select下拉菜单的列表
  await $store.dispatch('micro/magazine/GET_MAGAZINES')
  // 获得第一本杂志
  const MAGAZINES = $store.getters['micro/magazine/MAGAZINES']
  if (Array.isArray(MAGAZINES) && MAGAZINES.length) {
    // 3. 更新 magazineId
    $store.commit('micro/magazine/UPDATE_STATE', {
      name: 'magazineId',
      data: MAGAZINES[0].magazineId
    })
  }
}
