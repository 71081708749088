export default {
  name: 'onlineEditionerPiodicalMan',
  getPeriodTree: {
    url: '/rc-pub/api/rc-period/getPeriodTree'
  },
  getPeriodTreeForAlbum: {
    url: '/rc-pub/api/rc-period/getPeriodTreeForAlbum'
  },
  putOnShelves(id) {
    return `/rc-pub/api/rc-period/putOnShelves/${id}`
  },
  pullOffShelves(id) {
    return `/rc-pub/api/rc-period/pullOffShelves/${id}`
  },
  // 删除刊期
  delete(id) {
    return `/rc-pub/api/rc-period/delete/${id}`
  },
  // 网刊管理-优先出版-列表
  getFirstArticles: {
    url: '/rc-pub/api/rc-article/getFirstArticles'
  },
  // 网刊管理-当前期刊-列表
  getArticlesByPeriodId(id) {
    return `/rc-pub/api/rc-article/getArticlesByPeriodId/${id}`
  },
  // getArticlesByPeriodId
  getArticlesByPeriodicalId(id) {
    return `/rc-pub/api/rc-article/getArticlesByPeriodicalId/${id}`
  },
  // 网刊管理-优先出版-论文下架
  articlePullOff: {
    url: '/rc-pub/api/rc-article/pullOffShelves'
  },
  // 网刊管理-优先出版-论文发布
  articlePullOn: {
    url: '/rc-pub/api/rc-article/putOnShelves'
  },
  // 网刊管理-优先出版-删除
  articleDelete: {
    url: '/rc-pub/api/rc-article/delete'
  },
  articles: {
    url: `/&change/rc-api/periodicals/articles`
  },
  // 专辑列表
  albumSearch: {
    url: '/rc-pub/api/album/search'
  },
  // 获取可导入专辑列表
  importAlbumList: {
    url: '/rc-pub/api/album/importAlbumList'
  },
  // 添加导入专辑
  addImportAlbumList: {
    url: '/rc-pub/api/album/res/importRes'
  },
  // 优先出版下架前文案
  getPullOffMessage: {
    url: '/rc-pub/api/rc-article/pullOffShelvesMessage'
  },
  // 当前期刊下架前文案
  periodPullOffMessage(id) {
    return `/rc-pub/api/rc-period/pullOffShelvesMessage/${id}`
  },
  // 获取优先出版当前id数据信息
  getDetailApi(id) {
    return `/rc-pub/api/rc-article/getDetail/${id}`
  },
  // 编辑优先出版
  savePriotiry: {
    url: '/rc-pub/api/rc-article/save'
  },
  fileUpload: {
    url: '/rc-pub/api/files/fileUpload'
  },
  // 获取期刊id数据信息
  getCurrentApi(id) {
    return `/rc-pub/api/rc-period/${id}`
  },
  // 编辑优先出版
  saveCurrent: {
    url: '/rc-pub/api/rc-period/save'
  },
  // 获取图片列表
  getPictureList: {
    url: '/rc-pub/api/picture/page'
  },
  // 图片批量删除
  deletePicture(params) {
    const { ids = '', handler = '' } = params
    return `/rc-pub/api/picture/delete?ids=${ids}&handler=${handler}`
  },
  // 图片批量上架下架
  updateShelvesStatus(params = {}) {
    const { ids, shelvesStatus, handler = '' } = params
    return `/rc-pub/api/picture/updateShelvesStatus?ids=${ids}&shelvesStatus=${shelvesStatus}&handler=${handler}`
  },
  // 获取表格列表
  getTableList: {
    url: '/rc-pub/api/table/page'
  },
  // 表格批量删除
  deleteTable(params) {
    const { ids = '', handler = '' } = params
    return `/rc-pub/api/table/delete?ids=${ids}&handler=${handler}`
  },
  // 表格批量上架下架
  updateTableShelvesStatus(params) {
    const { ids, shelvesStatus, handler = '' } = params
    return `/rc-pub/api/table/updateShelvesStatus?ids=${ids}&shelvesStatus=${shelvesStatus}&handler=${handler}`
  },
  // 获取音频列表
  getAudioList: {
    url: '/rc-pub/api/audio/page'
  },
  // 音频批量上架下架
  updateAudioShelvesStatus: {
    url: '/rc-pub/api/audio/updateShelvesStatus'
  },
  // 音频批量删除
  deleteAudio: {
    url: '/rc-pub/api/audio/delete'
  },
  // 音频批量删除
  rcDocDelete: {
    url: '/rc-pub/api/rc-doc/delete'
  },
  // 获取视频列表
  getVideoList: {
    url: '/rc-pub/api/video/page'
  },
  // 获取科研数据列表
  getDocList: {
    url: '/rc-pub/api/rc-doc/getDocList'
  },
  getPullDocOffMessage: {
    url: '/rc-pub/api/rc-doc/pullOffShelvesMessage'
  },
  // 科研数据批量上架
  putOnShelvesDoc: {
    url: '/rc-pub/api/rc-doc/putOnShelves'
  },
  // 科研数据批量下架
  pullOffShelvesDoc: {
    url: '/rc-pub/api/rc-doc/pullOffShelves'
  },
  // 视频批量上架下架
  updateVideoShelvesStatus: {
    url: '/rc-pub/api/video/updateShelvesStatus'
  },
  // 视频批量删除
  deleteVideo: {
    url: '/rc-pub/api/video/delete'
  },
  // 更新文章类型是否为封面文章
  articleChangeType: {
    url: '/rc-pub/api/rc-article/changeType'
  },
  // 频道下的论文的年份列表
  getYearList: {
    url: '/rc-pub/api/rc-period/getYearList'
  },
  // 频道下的论文的期数
  getIssueListByYear: {
    url: '/rc-pub/api/rc-period/getIssueListByYear'
  },
  // 频道下的论文的刊物列表，刊群使用
  getSingleMagList: {
    url: '/rc-pub/api/rc-article/getSingleMagList'
  },
  // 专辑下的论文的刊物列表，刊群使用
  getAlbumSingleMagList: {
    url: '/rc-pub/api/rc-article/getAlbumSingleMagList'
  },
  // 专辑下的论文的年份列表
  getAlbumYearList: {
    url: '/rc-pub/api/rc-period/getYearList'
  },
  // 专辑下的论文的期数
  getAlbumIssueListByYear: {
    url: '/rc-pub/api/rc-period/getIssueListByYear'
  },
  // 保存站点doi注册参数
  savaDoiRegist: {
    url: '/rc-pub/api/doiRegist/save'
  },
  // 获取站点doi参数配置
  getDoiRegist: {
    url: '/rc-pub/api/doiRegist/get'
  },
  // 论文进行doi注册
  registDoiRegist: {
    url: '/rc-pub/api/doiRegist/regist'
  },
  // 查询doi注册报告
  getDoiReportList: {
    url: '/rc-pub/api/doiRegist/reportList'
  },
  // 获取关联资讯可添加的目标资源列表
  getRelationSource: {
    url: '/rc-pub/api/source-relations/getRelationSource'
  },
  // 保存资源关联关系
  relationSourceSave: {
    url: '/rc-pub/api/source-relations/save'
  },
  // 获取论文自定义下载权限
  getCustomAttach: {
    url: '/rc-pub/api/rc-article/getCustomAttach'
  },
  // 根据专辑id和专辑名称关键字模糊查询专辑列表
  getAlbumByWord: {
    url: '/rc-pub/api/album/getAlbumByName'
  },
  // 同步单刊专辑到虚拟专辑刊物下
  syncSingleAlbum: {
    url: '/rc-pub/api/album/syncSingleAlbum'
  },
  // 推送资源
  getPushList: {
    url: '/rc-pub/api/resource/getPushList'
  },
  // 获取待推送的组件列表
  pushResource: {
    url: '/rc-pub/api/resource/pushResource'
  }
}
