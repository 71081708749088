export function getQueryVariable(variable) {
  let query = window.location.search.substring(1)
  let vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return null
}
export const rules = {
  number: /^[0-9]+$/, // 校验是否正整数

  phone: /^1[0-9]{10}$|^\d{3,4}-\d{7,8}$/, // 验证“手机号”及“区号-固定电话号”
  mobile: /^1[0-9]{10}$/, // 手机号码

  cn: /^[\u4e00-\u9fa5]+$/, // 仅支持中文
  cnEn: /^[\u4e00-\u9fa5a-zA-Z]+$/, // 仅支持中英文
  cnNum: /^[A-Za-z0-9]+$/, // 仅支持英文和数字
  cnEnNum: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, // 仅支持中文、英文和数字
  cnEnNumTag: /^[\u4e00-\u9fa5a-zA-Z0-9!@#$%^&*()]+$/, // 中文、英文、数字和特殊字符

  // password: /^[a-zA-Z0-9\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E]{6,32}$/, // 密码长度不低于6,可用英文、数字和标点符号组合，英文区分大小写
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,32}$/, // 必须包含至少一个大小写和数字,长度为8-32位

  email: '^(\\w)+(\\.\\w+)*@(\\w)+((\\.\\w{2,3}){1,3})$', // 邮箱
  zipcode: /^[1-9]\d{5}$/, // 邮编
  url: /^((ht|f)tps?):\/\/([\w-]+(\.[\w-]+)*\/)*[\w-]+(\.[\w-]+)*\/?(\?([\w\-.,@?^=%&:/~+#]*)+)?/ // 校验是否是正确网址
}
export function addressSimple(val) {
  const tags = [
    '特别行政区',
    '维族自治区',
    '回族自治区',
    '壮族自治区',
    '自治区',
    '省',
    '市',
    '区',
    '县'
  ]
  tags.find((tag) => {
    const reg = new RegExp(tag + '$', 'g')
    if (reg.test(val)) {
      val = val.replace(reg, '')
      return true
    }
  })
  return val
}
