export default {
  name: 'firstList',
  getList: {
    url: '/article/firstList.do' // 获取刊物（韩桂林）
  },
  getStatus: {
    url: '/article/sectionState.do'
  },
  download: {
    url: '/work/magazine/journalBookMultiDown.do'
  },
  getPublisher: {
    url: '/article/getPublisher.do'
  },
  transfer: {
    url: '/article/transfer.do'
  },
  getHandler: {
    url: '/article/getHandler.do'
  },
  widthDrawArticle: {
    url: '/article/undoArticle.do'
  },
  groupPublication: {
    url: '/article/groupPublication.do'
  },
  journalYear: {
    url: '/magazine/journalYearList.do'
  },
  journalYearIssueList: {
    url: '/magazine/journalYearIssueList.do'
  },
  magazineDownload: {
    url: '/common/magazineDownload.do'
  },
  fileDownload: {
    url: '/common/download.do?file='
  },
  journalYearIssueColList: {
    url: '/magazine/journalColList.do'
  },
  manage: {
    url: '/article/versionManage.do'
  },
  priorDownload: {
    url: '/article/priorDownload.do'
  },
  refreshArticle: {
    url: '/article/refreshArticle.do'
  },
  htmlDownload: {
    url: '/article/htmlDownload.do'
  },
  htmlShow: {
    url: '/article/htmlShow.do'
  },
  tuningCheck: {
    url: '/article/tuningCheck.do'
  },
  importOneArticle: {
    url: '/resource/importonearticle.do'
  },
  magazineVersionXmlDownload: {
    url: '/common/magazineVersionXmlDownload.do'
  },
  articleReMulti: {
    url: '/article/articleReMulti.do'
  },
  wechat: {
    url: '/article/wechat.do'
  },
  blindTrialDownload: {
    url: '/common/blindTrialDownload.do'
  },
  articlelist: {
    url: '/resource/history/articlelist.do'
  },
  delete: {
    url: '/resource/history/delete.do'
  },
  backEdit: {
    url: '/article/backEdit.do'
  }
}
