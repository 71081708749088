export default {
  name: 'whiteListManage',
  // 白名单列表页
  getAuthorListByPage: {
    url: '/rc-api/VAS/push/whiteList/getAuthorListByPage.do'
  },
  // 新建白名单
  add: {
    url: '/rc-api/VAS/push/whiteList/add.do'
  },
  // 编辑
  update: {
    url: '/rc-api/VAS/push/whiteList/update.do'
  },
  // 导入
  import: {
    url: '/rc-api/VAS/push/whiteList/import.do'
  },
  // 删除
  delete: {
    url: '/rc-api/VAS/push/whiteList/delete.do'
  },
  // 推送历史查看
  pushHistory: {
    url: '/rc-api/VAS/push/whiteList/pushHistory'
  },
  // 导入模板下载
  ImportTemplateDownload: {
    url: '/rc-api/VAS/push/whiteList/importTemplateDownload.do'
  }
}
