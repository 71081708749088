export default {
  namespaced: true,
  state: {
    isCollapse: false,
    subIsCollapse: false,
    subMenuState: false
  },
  mutations: {
    UPDATE_STATE: (state, payload) => (state[payload.name] = payload.data)
  },
  actions: {}
}
