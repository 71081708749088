/**
 * 0:正常
 * 1——1000：微服务框架预留错误码
 * 1000——2000：E5平台错误码，为了支持国际化，不直接返回错误信息，而是使用错误码。
 * 业务系统应该从10000开始定义错误码
 * 1001：实际数据已经不存在，如一篇稿件已经被删除
 * 1002：实际数据的状态已经发生改变，如一篇稿件已经被别人提交审批了
 * 1003：数据已经被别人锁定，别人正在处理中。
 **/

import axios from 'axios'

const _axios = axios.create({
  baseURL: '',
  timeout: 200000 // request timeout
})
const yyptApiCludes = [
  'magazine/checkparam',
  'magazine/findMagazineById',
  'magazine/update',
  'magazine/gettemplatelist',
  'magazine/saveorupdaterevisionsetting',
  'magazine/getrevisionsettinginfo',
  'magazine/getGroupByMagazineId',
  'operate/notice',
  // 管理中心
  'magazine/getPortalMagazineMenu',
  '/magazine/getOrgNewVersion',
  'org/portalUser',
  '/org/',
  '/magazineMessage/',
  '/multi/',
  '/rc-manager/'
]
const userInfo = sessionStorage.getItem('userInfo')
  ? JSON.parse(sessionStorage.getItem('userInfo'))
  : null

// 添加token
_axios.interceptors.request.use(
  (config) => {
    // FIXME 临时token 能够放行 ids
    if (process.env.NODE_ENV !== 'production') {
      let token =
        // zxh 测试环境
        'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJpVHlTZ3pQUC1idEY5YkRzT3VNdXU2MXpidktxcWg5NGxMNkMyUnBTeVBZIn0.eyJleHAiOjE2NjUyMjQ2MjcsImlhdCI6MTY2NTIyMjgyNywianRpIjoiYTU0ZmMwNWQtMTM2Zi00NjQyLWJlZTItM2UzMmMzMGFiYmRkIiwiaXNzIjoiaHR0cHM6Ly9qb3VybmFsLmlkcy5zdGFnZXguZnp5dW4uY24vYXV0aC9yZWFsbXMvam91cm5hbCIsImF1ZCI6ImpvdXJuYWwtY29udHJpYnV0aW5nIiwic3ViIjoiMWNhNWViN2UtOWExNC00MDEwLWI0YmEtY2NkYzhlNjMwOWFjIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiam91cm5hbC1jb250cmlidXRpbmciLCJzZXNzaW9uX3N0YXRlIjoiOTY2M2QyNDktMWVlOS00YjY1LTgzNzItMjAwOTNjYTk0YWM1IiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vY29udHJpYnV0aW5nLnRlc3QucG9ydGFsLXRlc3QuZm91bmRlcnNzLmNuIl0sInJlc291cmNlX2FjY2VzcyI6eyJqb3VybmFsLWNvbnRyaWJ1dGluZyI6eyJyb2xlcyI6WyJ2aXNpdG9yIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIGNhc1VzZXJJZCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6InpoaCIsInByZWZlcnJlZF91c2VybmFtZSI6Inp4aCIsImZhbWlseV9uYW1lIjoiemhoIiwiZW1haWwiOiJ6aGFvaGhAZm91bmRlci5jb20ifQ.OosSojPwKXmn6JI3MgzZwlDd-PbPdbpqFOQo1bIDgmVpQhBMDUOH1enSLaQ31uFAPqRfzfc8W5PgVWE1FrBB7Jb3DuQ3RRyekuUCeyaiuz-_6KwXvJt9TSaZDkEmf9GZbwGLxoq76-akWAWBNhKjHZvF_TnOFGHe7Zf2_bkle5YexOczjnNE2I5Y9aLBANqiIIZ0q5h1MeYAxRBVLhSWAVZhL7qmm2a2qBrPT0BT5HDqDrlsUASCv27lIAXUmMBduZlap4-pwLqPWyGraEvGOTufKWkalQoIpJWhXqLqfxGjdhHVDLgip9wNMGABbQc7dciDc0mdAbA--THlQl6yPA'
      // zhao123 开发环境
      // 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI4SUpLOWJzM0pRNUctcU5jd18wZkhJUHpWTWFqVTNYLXlTTkFfdGs5Y2ZBIn0.eyJleHAiOjE2ODYyNzYwMDEsImlhdCI6MTY4Mzc3MDQwMSwianRpIjoiOThjYWQ3NzktZjlkOC00ZmM0LTlkYzgtNjcwNGExMzE3YzAxIiwiaXNzIjoiaHR0cHM6Ly9xaWthbi5pZHMudGVzdHguZnp5dW4uaW8vYXV0aC9yZWFsbXMvcWlrYW4iLCJhdWQiOiJxaWthbi1qb3VybmFsLWNvbnRyaWJ1dGluZyIsInN1YiI6IjM1MjJjZTY4LTllM2UtNDJmZC1hODRkLTU5MGE5YTI3ZTcxYyIsInR5cCI6IkJlYXJlciIsImF6cCI6InFpa2FuLWpvdXJuYWwtY29udHJpYnV0aW5nIiwic2Vzc2lvbl9zdGF0ZSI6ImFjOTdhNmU3LWFjMGItNDVlNS04YTM0LTk1N2Y3MWFmMGViZiIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vY29udHJpYnV0aW5nLmRldjYuZnp5dW4uaW8iXSwicmVzb3VyY2VfYWNjZXNzIjp7InFpa2FuLWpvdXJuYWwtY29udHJpYnV0aW5nIjp7InJvbGVzIjpbInZpc2l0b3IiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwgcGhvbmUiLCJzaWQiOiJhYzk3YTZlNy1hYzBiLTQ1ZTUtOGEzNC05NTdmNzFhZjBlYmYiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsIm5hbWUiOiLotbXmtbfmrKIiLCJwaG9uZV9udW1iZXIiOiIxODMwMTMwODcwMCIsInByZWZlcnJlZF91c2VybmFtZSI6InpoYW8xMjMiLCJmYW1pbHlfbmFtZSI6Iui1tea1t-asoiIsImVtYWlsIjoiYXNhQHFxLmNvbiJ9.FEnoQgeSP_fypCqjMreKgexojHQAjqFU1GAcXiVhgULnUQq0czKTf502dBSSM_1XWXVrUUi_Sw8WGzu-xBUU6DPYe65GxaaPdiX1JwCv_jv0m6RKXphY0IIX0hXVDHpMr0G9R89qMLLhuJGATXl6Ap97xXAoF2c1wwfn9UGdIwb0Iq2mMCGSSagKeVloE7_BqUy_nDuFtCpOWWSQqkP1bf9-XnWKZcm_IO8-y4SN6QjyZ2HRmW1NfJ_h8blrIV996LPZab4MabDVvkJsrozLoDEDj___PhJdnDkF6rPCsz9Q8uW3KG6h-LHnkDrJZzN7spPhE93uxo6y9JvMVWEKKQ'
      // guolinjing1 测试环境
      //'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJpVHlTZ3pQUC1idEY5YkRzT3VNdXU2MXpidktxcWg5NGxMNkMyUnBTeVBZIn0.eyJleHAiOjE2NjQ0MTgyMjcsImlhdCI6MTY2NDQxNjQyNywianRpIjoiOGVkNTgzYjgtM2YxNC00MzZlLThmYWEtOTM0ZmRhMTMwNWY2IiwiaXNzIjoiaHR0cHM6Ly9qb3VybmFsLmlkcy5zdGFnZXguZnp5dW4uY24vYXV0aC9yZWFsbXMvam91cm5hbCIsImF1ZCI6ImpvdXJuYWwtY29udHJpYnV0aW5nIiwic3ViIjoiNzBiOGI3YzQtYTM5MS00YmMxLTgzYjItNTA2NTc0NGE3YmNlIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiam91cm5hbC1jb250cmlidXRpbmciLCJzZXNzaW9uX3N0YXRlIjoiMDYxNmY3MzktMjAyYi00YjA2LWI4NTctZTZlN2E2YWQyZTA3IiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vY29udHJpYnV0aW5nLnRlc3QucG9ydGFsLXRlc3QuZm91bmRlcnNzLmNuIl0sInJlc291cmNlX2FjY2VzcyI6eyJqb3VybmFsLWNvbnRyaWJ1dGluZyI6eyJyb2xlcyI6WyJ2aXNpdG9yIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIGNhc1VzZXJJZCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6Imd1b2xpbmppbmcxIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiZ3VvbGluamluZzEiLCJmYW1pbHlfbmFtZSI6Imd1b2xpbmppbmcxIiwiZW1haWwiOiIyMjIwQHFxLmNvbSJ9.QEVN2ZiX-lyCd0k39RgOepRZxR4qi2l5dMo-EOe7D_SDEdzfQox6wJtqlcfDOScpmdcwHXVm2R5tYBwUuKxOFcYOUWg-dd0-dE-YJXHZTzI8w9vWCrjYA4CFoliac0aidsVCAtCOwVETXQpBR69eC9t8hop6WbCtmY3KKsK2fUb_jqLnJtVTr7XLo-D8Nkabmbo0fDwLCDGf_05p74z80KKvf53NpQilgAaP6zgWAXoJF7wQBwmapucH6VJHIHMdNF8bGDmQqCTwsd1N7oy6ptOealHXnMcdT4qy8IiQwn5HG1qAdy9stSqj5k9aFW0HfYvUoxAYJpVuxBPypRNySQ'
      // guolinjing2 测试环境
      // 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJpVHlTZ3pQUC1idEY5YkRzT3VNdXU2MXpidktxcWg5NGxMNkMyUnBTeVBZIn0.eyJleHAiOjE2NjIwMTgyODksImlhdCI6MTY2MjAxNjQ4OSwianRpIjoiYTllNjdmN2QtOGU3ZC00ZWNjLTliZGUtMGY4YmNmZmU1YWU1IiwiaXNzIjoiaHR0cHM6Ly9qb3VybmFsLmlkcy5zdGFnZXguZnp5dW4uY24vYXV0aC9yZWFsbXMvam91cm5hbCIsImF1ZCI6ImpvdXJuYWwtY29udHJpYnV0aW5nIiwic3ViIjoiMDJhMjExMjQtOGQ0My00OTc5LTkzZmItZDIzOWM2M2M2ZWRmIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiam91cm5hbC1jb250cmlidXRpbmciLCJzZXNzaW9uX3N0YXRlIjoiZjYyM2UzNmMtNWE5ZC00MzBhLWJlNGMtY2ViODc3ZGMyNWZiIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vY29udHJpYnV0aW5nLnRlc3QucG9ydGFsLXRlc3QuZm91bmRlcnNzLmNuIl0sInJlc291cmNlX2FjY2VzcyI6eyJqb3VybmFsLWNvbnRyaWJ1dGluZyI6eyJyb2xlcyI6WyJ2aXNpdG9yIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIGNhc1VzZXJJZCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6Imd1b2xpbmppbmcyIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiZ3VvbGluamluZzIiLCJmYW1pbHlfbmFtZSI6Imd1b2xpbmppbmcyIiwiZW1haWwiOiJndW9saW5qaW5nMkBpdWdlLmNvbSJ9.YmLE8dktxET7iaRg4wYZtic4KfcyuSvbQweK4tImzo61zK0U5nzP4EPAemVabHBwCPuZBGsPd_UHsiU04v6nwL-_LCEfRC6T-usmgWmEuwxb2M0eVnQAc3iiZkLhhxKxFWAHyJGHlRO6i773BkRACfgsIkwE64Qd4EJ7a2GJPfD_d0qQgvL2-otNkfyCDClmUFiG05rBfCSkQIXt-_4ynlSgRVz8UwAHtm0kUhmidjT0w1q95Zig2SzlQLVfcbW51YmUMdWKjjqaeA0pWj0WzMDLrjsIEbnEDfzcogNdFPY9CFspb7Z_NCDcSU29VhZlrf91POMsm4Vcj8CM902puw'
      // 编审中心用户权限
      let JournalAuth =
        // 宫丽杰 开发环境
        'eyJhY2NvdW50bmFtZSI6ImdvbmdsaWppZSIsImVtYWlsIjoiZ29uZ2xpamllQDEzOS5jb20iLCJtYWdhemluZWNvZGUiOiJ0ZXN0MDMiLCJtYWdhemluZWlkIjoiOTZjYjYyYzAzMzYxNDY1MzhiN2UzOTFmZWZiNTQwMGEiLCJtYWdhemluZW5hbWUiOiLljZXliIrmtYvor5UwMjE4Iiwib3JnQ29kZSI6InByZVJlbGVhc2UiLCJyb2xlaWQiOiJmZDU0ZTk0OS1hZDcwLTQ3NDItOTlkMC02NTA2OTk1MTJiYWEsNDA5YjkxNmFjNjNkNGY0Njg4YzM2NDI0N2M5Mzc1ZTYsOTU0Y2ZhNDVlNGI3NGM0NWI2MDk4NzQwM2E1MDg3NWMsZDFjOTRlNjYzNmJkNGRlZGE2MTNkNDJiZGZmYWRmMWIsMmY2Njc5MTg1M2I0NDg3MjgzOTFlMThiZjJkOTEzZDIiLCJ0ZWxlcGhvbmUiOiIxMzkxMDAyMzE4OSIsInVzZXJpZCI6IjU0MTFmMDQ0NTU5NDRjODVhNWFjNjQ1NWE2ZmQxYjMzIiwidXNlcm5hbWUiOiLlrqvkuL3mnbAifQ=='
      config.headers.Authorization = `Bearer ${token}`
      config.headers.JournalAuth = JournalAuth
    }
    if (config.url.indexOf('/magazine/') !== -1) {
      config.headers['orgCode'] = sessionStorage.getItem('orgCode')
    }
    yyptApiCludes.forEach((item) => {
      if (config.url.indexOf(item) !== -1) {
        config.headers.portal = '77aaf908d3994a95bf4a8e3ca31851ea'
        config.headers.orgCode = 'magazinemanager'
      }
    })
    config.headers.rcpub = sessionStorage.getItem('orgCode')
    config.headers.operatorLoginName = userInfo && userInfo.loginName
    return config
  },
  (error) => {
    let response = error.response
    if (
      response.status === 401 ||
      response.status === '401' ||
      (response.data && response.data.status === 401)
    ) {
      // Store.commit('auth/LOGOUT')
    }
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use((response) => {
  const status = response.data.status
  if (window.location.pathname.indexOf('/updating') === -1) {
    if (status == '666') {
      window.location.href =
        '/updating?r=' +
        window.location.pathname +
        window.location.search +
        `&t=${response.data?.data?.time || ''}`
    }
  }
  return response
})

export default _axios
