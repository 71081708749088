import axios from './axios'
import Vue from 'vue'
// import { isPermitted } from './index'

export function createAPI(url, method, config, vconfig = {}) {
  config = config || {}
  // 判断是否已经登陆
  // if (!vconfig.passAuth && !isPermitted()) {
  //   handleNotLogin()
  // }
  // 处理路径请求中的路径参数
  url = handlePathParams(url, config)
  if (method === 'get') {
    config.params = config.params || {}
    config.params.timestamps = new Date().getTime()
  }

  if (url.indexOf('/je-api/journal-edit') > -1 && Vue.prototype.$commonStore) {
    // 对于访问编审中心接口 需要判断权限
    return checkAuth(url, method, config, vconfig)
  }
  return axios({
    url,
    method,
    ...config,
    vconfig
  })
}
function checkAuth(url, method, config, vconfig) {
  return Vue.prototype.$commonStore
    .dispatch('micro/magazine/GET_CENTER_AUTH')
    .then((resp) => {
      //刊群类型，0：标准集群，1：数据库集群，2：实体集群(采编)，3:联盟 默认为0
      const groupType =
        Vue.prototype.$commonStore.state?.micro?.magazine?.magazineInfo
          ?.groupType

      if (resp?.editingApp == 1 || groupType == 2) {
        return axios({
          url,
          method,
          ...config,
          vconfig
        })
      } else {
        return Promise.reject()
      }
    })
}
/**
 * 处理未登录的情况
 * 1. 提示报错
 * 2. 路由跳转
 * 3. 提示信息
 */
// function handleNotLogin() {
//   // 1. 提示报错
//   Promise.reject(new Error('没有授权登陆！'))
//   // 2. 路由跳转
//   // Store.commit('auth/LOGOUT')
//   // 3. 提示信息
// }

// 处理路径请求中的路径参数
function handlePathParams(url, config) {
  if (config && config.params && typeof config.params === 'object') {
    return Object.keys(config.params).reduce((pre, cur) => {
      return pre.replace(`{${cur}}`, config.params[cur])
    }, url)
  }
  return url
}

export default createAPI
