export default {
  name: 'groupJournal',
  journalList: {
    url: '/magazine/journalList.do' //获取刊物（韩桂林）
  },
  journalRcList: {
    url: '/magazine/journalRcList.do' // 获取资源中心刊物列表 (何垄）
  },
  journalTreeList: {
    url: '/magazine/journalTreeList.do' //获取刊物年份刊期栏目树（韩桂林）
  },
  magazineColumn: {
    url: '/magazine/magazineColumn/list.do' //刊物栏目列表（武岳明）
  },

  bookArticleList: {
    url: '/article/bookArticleList.do' //组版列表（韩桂林）
  },

  upperEditionList: {
    url: '/article/upperEditionList.do' //上版列表（韩桂林）
  },
  upperEditionSubmit: {
    url: '/article/upperEditionSubmit.do' //选稿上版（韩桂林）
  },
  platoon: {
    url: '/article/platoon.do' //连排（韩桂林）
  },
  placeHolder: {
    url: '/article/placeHolder.do' //补白（韩桂林）
  },
  placeHolderDbData: {
    url: '/article/placeHolderDbData.do' //补白（韩桂林）
  },
  placeHolderFile: {
    url: '/article/placeHolderFile.do' //补白（韩桂林）
  },
  placeHolderDel: {
    url: '/article/placeHolderDel.do' //补白（韩桂林）
  },
  deletePlaceHolder: {
    url: '/article/articleHoldDelete.do' //补白文件删除（韩桂林）
  },
  separate: {
    url: '/article/separate.do' //拆版（韩桂林）
  },
  outEdition: {
    url: '/article/outEdition.do' //下版（韩桂林）
  },
  catalogCheck: {
    url: '/book/catalogCheck.do' //整期合成目录校验（胡琛）
  },
  combine: {
    url: '/book/combine.do' //整期合成（胡琛）
  },
  huiBianCombine: {
    url: '/huibian/huiBianCombine.do'
  },
  refreshBook: {
    url: '/book/refreshBook.do' //获取整期合成结果更新（胡琛）
  },
  magazineDownload: {
    url: '/common/magazineDownload.do' //下载整期合成结果（胡琛）
  },
  fileDownload: {
    url: '/common/download.do?file='
  },
  htmlDownload: {
    url: '/article/htmlDownload.do'
  },
  htmlShow: {
    url: '/article/htmlShow.do'
  },
  magazineVersionXmlDownload: {
    url: '/common/magazineVersionXmlDownload.do'
  },
  wechat: {
    url: '/article/wechat.do'
  },
  preadDownload: {
    url: '/article/exportreport.do'
  },
  bookDownload: {
    url: '/common/bookDownload.do' //下载整期合成结果（胡琛）
  },
  tuningSubmit: {
    url: '/book/tuningSubmit.do' //精修（胡琛）
  },
  issueSubmit: {
    url: '/article/issueSubmit.do' //付印（韩桂林）
  },
  multiResult: {
    url: '/book/multiResult.do' //打包下载--格式进度
  },
  journalBookMultiDown: {
    url: '/book/download.do' //打包下载（胡琛）
  },
  backGroup: {
    url: '/article/backGroup.do' //退回组刊（韩桂林）
  },
  columnOrder: {
    url: '/article/columnOrder.do' //更换栏目（韩桂林）
  },
  updateStartPageNum: {
    url: '/article/updateStartPageNum.do' //更换起始页码（韩桂林）
  },
  issueColumnSave: {
    url: '/article/issueColumnSave.do' //保存刊期栏目（武岳明）
  },
  columnDelete: {
    url: '/magazine/magazineIssue/columnDelete.do' //删除刊期栏目（武岳明）
  },
  delete: {
    url: '/magazine/magazineIssue/delete.do' //删除刊期（武岳明）
  },
  updateTreeOrder: {
    url: '/magazine/magazineColumn/updateTreeOrder.do' //调整刊物栏目顺序（武岳明）
  },
  articleOrder: {
    url: '/article/articleOrder.do' //稿件移动（韩桂林）
  },
  columnList: {
    url: '/article/columnList.do' //获取未使用的栏目（武岳明）
  },
  columnSave: {
    url: '/article/columnSave.do' //保存栏目（武岳明）
  },
  bookVersionList: {
    url: '/book/bookVersionList.do' //版本管理（韩桂林）
  },
  bookRefreshArticle: {
    url: '/article/bookRefreshArticle.do' //整书单篇刷新（韩桂林）
  },
  tuningCheck: {
    url: '/article/tuningCheck.do'
  },
  bookZipDownload: {
    url: '/book/bookZipDownload.do'
  },
  journalYearIssueColList: {
    url: '/magazine/journalYearIssueColList.do'
  },
  importjournalarticle: {
    url: '/resource/importjournalarticle.do' // 整刊入库
  },
  refreshIssue: {
    url: '/book/refreshIssue.do' //决定是否展示入库按钮
  },
  deleteResource: {
    url: '/resource/history/delete.do'
  },
  issuelist: {
    url: '/resource/history/issuelist.do'
  },
  jiaoCiFileList: {
    url: '/book/jiaoCiFileList.do'
  },
  jiaoCiFileDel: {
    url: '/book/jiaoCiFileDel.do'
  },
  jiaoCiFileSave: {
    url: '/book/jiaoCiFileSave.do'
  },
  jiaoCiFileZip: {
    url: '/book/jiaoCiFileZip.do'
  },
  getPublisher: {
    url: '/book/getPublisher.do'
  },
  getSelfPublisher: {
    url: '/article/getPublisher.do'
  },
  selfTruingCheck: {
    url: '/book/articleEditCheck.do'
  },
  articleEdit: {
    url: '/book/articleEdit.do'
  },
  microwkJoin: {
    url: '/magazine/microwkJoin.do'
  },
  issueCancel: {
    url: '/article/issueCancel.do'
  },
  // 上传整期封面
  uploadCover: {
    url: '/magazine/magazineIssue/uploadCover.do'
  },
  // 下载整期封面
  downCover: {
    url: '/magazine/magazineIssue/downCover.do'
  },
  // 元数据下载
  tiluDown: {
    url: '/book/tiluDown.do'
  },
  // 目录置顶或底
  catalogZhiMove: {
    url: '/article/catalogZhiMove.do'
  },
  // 下载校次流程单
  jiaociFlowSheet: {
    url: '/book/jiaociFlowSheet.do'
  }
}
