export default {
  name: 'weChatPush',
  // 微信传播
  GetMagByPlatMagId: {
    url: '/das-api/vas/mag/getMagByPlatMagId'
  },
  // 获取最新微网刊
  FindFirstNetworkJournal: {
    url: '/das-api/vas/network/findFirstNetworkJournal'
  },
  // 获取历史所有微网刊
  GetNetWorkArticles: {
    url: '/das-api/vas/network/getNetworkArticles'
  },
  // 获取统计信息
  GetNetworkStatistics: {
    url: '/das-api/vas/network/getNetworkStatistics'
  },
  // 文章点击量排行
  GetArticleAccess: {
    url: '/das-api/vas/network/getArticleAccess'
  },
  // 学者活跃度排行
  GetUserAccess: {
    url: '/das-api/vas/network/getUserAccess'
  },
  // 获取每期微网刊基本信息
  GetNetworkJournalInfo: {
    url: '/das-api/vas/getNetworkJournalInfo'
  }
}
