export default {
  name: 'periodPush',
  pushTaskList: {
    url: '/rc-api/VAS/push/task/pushTaskList'
  },
  // 整期或者专题 新建
  createPushTask: {
    url: '/rc-api/VAS/push/task/createPushTask.do'
  },
  // 编辑
  updatePushTask: {
    url: '/rc-api/VAS/push/task/updatePushTask.do'
  },
  // 复制
  copy: {
    url: '/rc-api/VAS/push/task/copy.do'
  },
  // 删除
  delete: {
    url: '/rc-api/VAS/push/task/delete.do'
  },
  // 试推送
  trySend: {
    url: '/rc-api/VAS/push/task/trySend.do'
  },
  // 推送
  send: {
    url: '/rc-api/VAS/push/task/send.do'
  },
  // 列表
  articles: {
    url: '/rc-api/periodicals/articles.do'
  },
  // 获取模板列表
  getTemplateList: {
    url: '/rc-api/VAS/push/templateManage/getTemplateList.do'
  },
  // 获取刊期列表
  operation: {
    url: '/rc-api/periodicals/operation'
  },
  // 获取白名单列表
  getAuthorList: {
    url: '/rc-api/VAS/push/whiteList/getAuthorListByPage'
  },
  getWhiteList: {
    url: '/rc-api/VAS/push/task/getTaskSendAuthors'
  },
  getTaskArticles: {
    url: '/rc-api/VAS/push/task/getTaskArticles'
  },
  deleteBatchPushHistory: {
    url: '/rc-api/VAS/push/task/deleteBatchPushHistory'
  },
  exportPushResult: {
    url: '/rc-api/VAS/push/statistic/download'
  },
  getTaskArticlesAndAuthors: {
    url: '/rc-api/VAS/push/task/getTaskArticlesAndAuthors'
  },
  theses: {
    url: '/rc-api/VAS/push/task/theses'
  },
  chooseArticle: {
    url: '/rc-api/VAS/push/task/chooseArticle'
  },
  // 整期的选择文章
  wholeArticles: {
    url: '/rc-api/VAS/push/task/articles'
  }
}
