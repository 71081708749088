module.exports = transferArrayToObject([
  /*************************** increment-service **************************** */
  {
    context: ['/das-api/'],
    // target: 'http://www.das-dev.publish-test.founderss.cn', // 增值服务测试环境
    target: 'http://www.das.publish.founderss.cn', //正式环境
    changeOrigin: true,
    secure: false
  },
  /*************************** publish-center **************************** */
  {
    context: ['/rc-pub/'],
    // target: 'https://journal.portal.founderss.cn',
    target: 'http://gateway-web.publish.testx.fzyun.io',
    changeOrigin: true,
    secure: false
  },
  /*************************** portal **************************** */
  {
    context: ['/portal/'],
    // 原阿里云测试环境
    // target: 'http://magazine-portal.portal-test.founderss.cn',
    // 原阿里云开发环境
    // target: 'http://magazine-portal.dev6.fzyun.io',
    // 现公司测试环境
    target: 'http://magazine-portal.testx.fzyun.io',
    // 现公司开发环境
    // target: 'http://magazine-portal.devx.fzyun.io',
    changeOrigin: true,
    secure: false
  },
  /*************************** resource **************************** */
  {
    context: ['/rc-api/', '/journal-web/'],
    // target: 'http://gateway-web.rc.dev6.fzyun.io',
    target: 'http://gateway-web.publish.testx.fzyun.io',
    changeOrigin: true,
    secure: false
  },
  /*************************** editing **************************** */
  {
    context: ['/je-api/'],
    // 开发环境
    // target: 'http://web.mag-edit.dev6.fzyun.io',
    // 测试环境
    target: 'http://web-mag-edit.testx.fzyun.io',
    changeOrigin: true,
    secure: false
  },
  /*************************** product **************************** */
  {
    context: [
      '/magazine/',
      '/work/',
      '/article/',
      '/book/',
      '/common/',
      '/index/',
      '/userCenter/',
      '/upload/',
      '/importArticle/',
      '/resource/',
      '/comparisonversion/',
      '/newUI/',
      '/huibian/',
      '/tache/'
    ],
    target: 'https://prerelease.testx.fzyun.io',
    // target: 'http://172.19.57.197:8181',
    // target: 'http://uitest.journal-test.founderss.cn/',
    // target: 'http://172.19.36.125:8080', // 韩桂林
    // target: 'http://172.19.36.154:8081', // 马康
    changeOrigin: true,
    secure: false
    // target: 'http://47.92.117.39:8086',
    // target:'http://47.92.99.14:8080', //开发环境
    // target: 'http://172.19.57.197:8180' // [20210118]开发环境
  },
  /*************************** Platform Operation **************************** */
  {
    context: [
      '/group',
      '/org/',
      '/magazine/',
      '/magazineMessage/',
      '/magazineMessageOther/'
    ],
    target: 'http://magazine-manager.testx.fzyun.io',
    changeOrigin: true,
    secure: false
  },
  /*************************** 业务中台 **************************** */
  {
    context: ['/baas-api', '/template-key', '/template/'],
    // target: 'https://baas-message.testx.fzyun.io/',
    // target: 'https://journal.testx.fzyun.io/',
    target: 'https://baas-gateway.testx.fzyun.cn/',
    changeOrigin: true,
    secure: false,
    // 本地开发要加上这个地址，但是千万切记别提交了
    pathRewrite: {
      '^/baas-api': ''
    }
  },
  /*************************** 大数据中心 **************************** */
  {
    context: [
      '/adcs-api/',
      '/adcs-api/api/',
      '/adcs-api/api/article/',
      '/article/',
      '/adcs-api/api/org/',
      '/org/',
      '/api/'
    ],
    target: 'https://adcs-web.testx.fzyun.io/',
    changeOrigin: true,
    secure: false,
    pathRewrite: {
      '^/adcs-api': ''
    }
  }
])

/**
 * 把对象转成数组 - proxy 不支持数组形式
 * 输入：[ { context: [ '/auth', '/api' ], target: 'http://localhost:3000' } ]
 * 输出：{'/auth': { target: 'http://localhost:3000' }, '/api': { target: 'http://localhost:3000' }}
 *
 * @param rules 代理表数组
 * @returns {*}
 */
function transferArrayToObject(rules) {
  return rules.reduce((pre, cur) => {
    if (!Array.isArray(cur.context)) {
      throw new Error(`${JSON.stringify(cur)} 中的 context 不是数组！`)
    }
    // 拷贝一份 proxy 中的 context
    const _context = Array.prototype.slice.call(cur.context)

    // 删除原对象中的 context 属性。转换后的对象不需要context属性，因此在合并前，删掉这个属性
    delete cur.context

    _context.forEach((apiPath) => {
      pre[apiPath] = {
        ...cur
      }
    })
    return pre
  }, {})
}
