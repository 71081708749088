export default {
  name: 'userManage',
  getUserList: {
    url: '/userCenter/user/list.do'
  },
  uploadImg: {
    url: '/userCenter/user/upload.do'
  },
  deleteUser: {
    url: '/userCenter/user/delete.do'
  },
  resetpwd: {
    url: '/userCenter/user/resetpwd.do'
  },
  getuserrolemagazine: {
    url: '/userCenter/user/getroleandmagazine.do'
  },
  save: {
    url: '/userCenter/user/saveorupdateuser.do'
  },
  getUserInfo: {
    url: '/userCenter/user/userinfoedit.do'
  },
  checkloginName: {
    url: '/userCenter/user/checkloginName.do'
  },
  checkmobile: {
    url: '/userCenter/user/checkmobile.do'
  },
  getcreatecoder: {
    url: '/userCenter/user/getcreatecoder.do'
  },
  deleteTransfer: {
    url: '/userCenter/user/deleteTransfer.do'
  }
}
