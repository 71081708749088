import portal from './portal'
import message from './message'
import article from './article'
import review from './review'

export default {
  portal,
  message,
  article,
  review
}
