export default {
  name: 'columnManage',
  list: {
    url: '/magazine/magazineColumn/list.do'
  },
  save: {
    url: '/magazine/magazineColumn/save.do'
  },
  del: {
    url: '/magazine/magazineColumn/delete.do'
  },
  move: {
    url: '/magazine/columnOrder/update.do'
  },
  issueFinishCheck: {
    url: '/magazine/issueFinishCheck.do' // 栏目是否已签发的校验（武岳明）
  },
  updateArchive: {
    url: '/magazine/magazineColumn/updateArchive.do' // 开启或关闭栏目状态
  }
}
