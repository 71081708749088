// 库操作
export default {
  name: 'library',
  // 1.获取文档库列表
  docLibs: {
    url: '/rc-api/columns'
  },
  //2.获取文档库资源个数
  count(columnId) {
    return `/rc-api/columns/${columnId}/count`
  },
  //3.新建资源库
  addDocLib: {
    url: `/rc-api/columns`
  },
  //4.删除资源库/获取单个/删除库
  docLib(columnId) {
    return `/rc-api/columns/${columnId}`
  },
  //获取刊物列表
  getJournalList: {
    url: '/magazine/journalList.do'
  },
  // 修改库
  editLib(columnId) {
    return `/rc-api/columns/${columnId}`
  },
  // 获取专家库和作者库右侧列表数量字段
  getAuthorNumber: {
    url: '/rc-api/authorLibs/getAuthorNumber'
  },
  getLibNumber: {
    url: '/rc-api/columns/batchGetDocCount'
  }
}
